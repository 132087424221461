const WAITING = 1;
const APPROVED = 2;
const REJECTED = 3;
const CANCELED = 4;

export default function getRequestStatusColor(type) {
  switch (type) {
    case WAITING:
      return "warning";
    case APPROVED:
      return "success";
    case REJECTED:
      return "danger";
    case CANCELED:
      return "danger";
    default:
      return "";
  }
}
