import { Article, Close } from "@mui/icons-material";
import {
  Avatar,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

export default function ChooseContractDialog(props) {
  const { onClick, onClose, contracts, open } = props;

  return (
    <Dialog fullWidth maxWidth={"sm"} onClose={onClose} open={open}>
      <DialogTitle>Selecione o contrato</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <List sx={{ pt: 0 }}>
        {contracts.map(contract => (
          <ListItem disableGutters key={contract.APC_ID}>
            <ListItemButton onClick={() => onClick(contract.APC_ID)}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "#3C8DBC", color: "#FFFFFF" }}>
                  <Article />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={contract.APC_DESC} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
