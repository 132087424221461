import React, { useMemo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { Badge } from "react-bootstrap";
import moment from "moment";

import getRequestType from "../../../../../utils/getRequestType";
import getRequestStatus from "../../../../../utils/getRequestStatus";
import getRequestStatusColor from "../../../../../utils/getRequestStatusColor";
import { integrateRequest } from "../../../../../services/mobileApi";

export default function MobileRequestTable({ data, rows }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = paginationModel => {
    searchParams.set("page", paginationModel.page + 1);
    searchParams.set("page_size", paginationModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(paginationModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Tipo",
        field: "type",
        flex: 1,
        renderCell: params => {
          return getRequestType(params.row?.type);
        },
      },
      {
        headerName: "Matricula",
        field: "enrollNumber",
        flex: 1,
        renderCell: params =>
          params.row.created_by?.info?.[0]?.data
            ? JSON.parse(params.row.created_by?.info?.[0]?.data)?.enroll_number
            : "",
      },
      {
        headerName: "Nome",
        field: "name",
        flex: 1,
        renderCell: params => params.row.created_by?.info?.[0]?.name,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        renderCell: params => params.row?.created_by?.email,
      },
      {
        headerName: "Criado em",
        field: "created_at",
        flex: 1,
        renderCell: params =>
          moment(params.row?.created_at).format("DD/MM/YYYY - HH:mm"),
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        renderCell: params => {
          return (
            <Badge variant={getRequestStatusColor(params.row?.status)}>
              {getRequestStatus(params.row?.status)}
            </Badge>
          );
        },
      },
      {
        headerName: "Integrado",
        field: "integrated",
        flex: 1,
        renderCell: params => {
          return (
            <Badge variant={getRequestStatusColor(params.row?.integrate_status)}>
              {getRequestStatus(params.row?.integrate_status)}
            </Badge>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            onClick={() => {
              searchParams.set("request_id", params.row.id);
              setSearchParams(searchParams);
            }}
            label="Visualizar"
            showInMenu
          />,
          <GridActionsCellItem
            onClick={() => {
              const requestData = JSON.parse(params.row.request_data);

              integrateRequest(params.row.id, {
                companyCode: requestData.company_code,
                subsidiaryCode: requestData.subsidiary_code,
              });
            }}
            label="Abrir solicitação"
            showInMenu
          />,
        ],
      },
    ],
    [searchParams, setSearchParams],
  );

  return (
    <DataGrid
      rows={data}
      columns={columns}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}
