import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

//import MoneyTextField from "../../../../../components/react-form/MoneyTextField";

export default function VacationForm({ setValue, control, errors, watch }) {
  const hasLoan = watch("hasLoan");
  const hasAbatementPeriod = watch("hasAbatementPeriod");

  useEffect(() => {
    if (hasLoan === "2") {
      setValue("loan", "");
    }
  }, [hasLoan, setValue]);

  useEffect(() => {
    if (hasAbatementPeriod === "2") {
      setValue("abatementPeriod", "");
      setValue("abatementDays", "");
    }
  }, [hasAbatementPeriod, setValue]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="Início das férias"
                type="date"
                margin="normal"
                fullWidth
                name="startDate"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.startDate ? true : false}
                helperText={errors?.startDate?.message}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            name="startDate"
          />
        </div>
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="Quantidade de dias"
                type="number"
                margin="normal"
                fullWidth
                name="numberOfDays"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.numberOfDays ? true : false}
                helperText={errors?.numberOfDays?.message}
                onChange={onChange}
              />
            )}
            name="numberOfDays"
          />
        </div>
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Adiantamento 13"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.hasAdvance13 ? true : false}
                helperText={errors?.hasAdvance13?.message}
                onChange={onChange}
              >
                <MenuItem value={"1"}>Sim</MenuItem>
                <MenuItem value={"2"}>Não</MenuItem>
              </TextField>
            )}
            name="hasAdvance13"
          />
        </div>
      </div>
      {/*
      <div className="row">
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Emprestimo CCT/ACT"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.hasLoan ? true : false}
                helperText={errors?.hasLoan?.message}
                onChange={onChange}
              >
                <MenuItem value={"1"}>Sim</MenuItem>
                <MenuItem value={"2"}>Não</MenuItem>
              </TextField>
            )}
            name="hasLoan"
          />
        </div>
        {hasLoan === "1" && (
          <div className="col-md-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <MoneyTextField
                  required
                  disabled
                  label="Valor do emprestimo"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.loan ? true : false}
                  helperText={errors?.loan?.message}
                  onChange={onChange}
                />
              )}
              name="loan"
            />
          </div>
        )}
      </div> */}
      <div className="row">
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Possui período de abono"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.hasAbatementPeriod ? true : false}
                helperText={errors?.hasAbatementPeriod?.message}
                onChange={onChange}
              >
                <MenuItem value={"1"}>Sim</MenuItem>
                <MenuItem value={"2"}>Não</MenuItem>
              </TextField>
            )}
            name="hasAbatementPeriod"
          />
        </div>
        {hasAbatementPeriod === "1" && (
          <>
            <div className="col-md-4">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    required
                    disabled
                    select
                    label="Período de abono"
                    margin="normal"
                    fullWidth
                    variant="standard"
                    value={value}
                    onBlur={onBlur}
                    error={errors?.abatementPeriod ? true : false}
                    helperText={errors?.abatementPeriod?.message}
                    onChange={onChange}
                  >
                    <MenuItem value={"1"}>Antes</MenuItem>
                    <MenuItem value={"2"}>Depois</MenuItem>
                  </TextField>
                )}
                name="abatementPeriod"
              />
            </div>
            <div className="col-md-4">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    required
                    disabled
                    label="Dias de abono pecuniário"
                    margin="normal"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={value}
                    onBlur={onBlur}
                    error={errors?.abatementDays ? true : false}
                    helperText={errors?.abatementDays?.message}
                    onChange={onChange}
                  />
                )}
                name="abatementDays"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
