import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export function useApp() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const [uploadList, setUploadList] = useState([]);

  const pushDocumentToUploadList = document => {
    setUploadList(list => list.concat(document));
  };

  const setDocumentInUploadList = (documentId, data) => {
    setUploadList(list =>
      list.map(document => {
        if (document.id === documentId) {
          return data;
        }

        return document;
      }),
    );
  };

  return (
    <AppContext.Provider
      value={{ uploadList, pushDocumentToUploadList, setDocumentInUploadList }}
    >
      {children}
    </AppContext.Provider>
  );
}
