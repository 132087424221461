import { Close, Print } from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { makeStyles } from "@mui/styles";

import {
  getPayrollHistory,
  getPayrollUrl,
} from "../../../../../services/pwsApi";
import { LoadingBox } from "../../../../../../components/utils";
import EnrollFormDialog from "./EnrollFormDialog";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

export default function PaymentReceiptsDialog(props) {
  const classes = useStyles();
  const { onClose, open, enrollNumber, companyCode, subsidiaryCode } = props;
  const [payrollReference, setPayrollReference] = useState();
  const [payrollType, setPayrollType] = useState();
  const [payrollUrl, setPayrollUrl] = useState();

  const payrollHistoryQuery = useQuery(
    ["payroll-history", companyCode, subsidiaryCode, enrollNumber],
    () => getPayrollHistory(companyCode, subsidiaryCode, enrollNumber),
    {
      onError: () => toast.error("Erro ao carregar os recibos de pagamento."),
      enabled: !!enrollNumber,
    },
  );

  const data = payrollHistoryQuery?.data?.data?.data ?? [];

  const payrollQuery = useQuery(
    [
      "payroll",
      companyCode,
      subsidiaryCode,
      enrollNumber,
      payrollReference,
      payrollType,
    ],
    () =>
      getPayrollUrl(
        companyCode,
        subsidiaryCode,
        enrollNumber,
        payrollReference,
        payrollType,
      ),
    {
      onSuccess: data => {
        setPayrollUrl(data?.data);
      },
      onError: () => toast.error("Erro ao buscar o recibo de pagamento."),
      enabled: !!payrollReference && !!payrollType,
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo(
    () => [
      {
        headerName: "Matricula",
        field: "RD_MAT",
        flex: 1,
      },
      {
        headerName: "Nome",
        field: "RA_NOME",
        flex: 1,
      },
      {
        headerName: "Descrição",
        field: "DESC_VERBA",
        flex: 1,
      },
      {
        headerName: "Roteiro",
        field: "ROTEIRO",
        flex: 1,
      },
      {
        headerName: "Valor",
        field: "RD_VALOR",
        flex: 1,
        renderCell: params => {
          return Number(params.row.RD_VALOR).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        },
      },
      {
        headerName: "Competência",
        field: "RD_PERIODO",
        flex: 1,
        renderCell: params => {
          return moment(params.row.RD_PERIODO, "YYYYMM").format("MM/YYYY");
        },
      },
      {
        headerName: "Dt. pagamento",
        field: "RD_DATPGT",
        flex: 1,
        renderCell: params => {
          return moment(params.row.RD_DATPGT, "YYYYMMDD").format("DD/MM/YYYY");
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            onClick={() => {
              setPayrollReference(params.row.RD_PERIODO);
              setPayrollType(params.row.ROTEIRO);
            }}
            icon={<Print />}
            label="Recibos de pagamento"
          />,
        ],
      },
    ],
    [],
  );

  return (
    <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
      <DialogTitle>Recibos de pagamento</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ height: "100vh" }}>
        <Backdrop className={classes.backdrop} open={payrollQuery.isLoading}>
          <LoadingBox />
        </Backdrop>
        <EnrollFormDialog
          uri={payrollUrl}
          onClose={(_, reason) => {
            if (reason && reason === "backdropClick") return;
            setPayrollUrl(undefined);
            setPayrollReference(undefined);
            setPayrollType(undefined);
          }}
        />
        <DataGrid
          getRowId={row => row.R_E_C_N_O_}
          rows={data}
          columns={columns}
          loading={payrollHistoryQuery.isLoading}
          sx={{ flex: 1, border: "none" }}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableRowSelectionOnClick
        />
      </DialogContent>
    </Dialog>
  );
}
