import React, { useMemo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import { Badge } from "react-bootstrap";

import maskCpf from "../../../../../utils/maskCpf";

export default function MobileUserTable({ data, rows }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = paginationModel => {
    searchParams.set("page", paginationModel.page + 1);
    searchParams.set("page_size", paginationModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(paginationModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "CPF",
        field: "cpf",
        flex: 1,
        renderCell: params => maskCpf(params.row?.user?.cpf),
      },
      {
        headerName: "Matricula",
        field: "enrollNumber",
        flex: 1,
        renderCell: params => JSON.parse(params.row?.data)?.enroll_number,
      },
      {
        headerName: "Nome",
        field: "name",
        flex: 1,
      },
      {
        headerName: "Email",
        field: "email",
        flex: 1,
        renderCell: params => params.row?.user?.email,
      },
      {
        headerName: "Email verificado",
        field: "verified",
        flex: 1,
        renderCell: params => {
          return (
            <Badge
              variant={
                !!params.row?.user?.email_verified_at ? "success" : "danger"
              }
            >
              {!!params.row?.user?.email_verified_at ? "Sim" : "Não"}
            </Badge>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            onClick={() => {
              searchParams.set("user_id", params.row.user_id);
              setSearchParams(searchParams);
            }}
            label="Editar"
            showInMenu
          />,
        ],
      },
    ],
    [searchParams, setSearchParams],
  );

  return (
    <DataGrid
      rows={data}
      columns={columns}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}
