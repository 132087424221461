/* eslint-disable no-sequences */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { object } from "yup";
import { Autocomplete } from "@mui/material";

import { getUsers } from "../../../services/vmApi";

export function UserDialog({ open, onClose, onConfirm }) {
  const [errors, setErrors] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [user, setUser] = useState(null);

  const validateSchema = object({
    user: object()
      .nullable()
      .required(),
  });

  const validateIfHasError = (fieldName, value) => {
    if (errors?.[fieldName]) {
      validateSchema.validateAt(fieldName, { [fieldName]: value }).then(() => {
        const newErrors = errors;
        delete newErrors[fieldName];

        setErrors(newErrors);
      });
    }
  };

  const fetchUsers = useCallback(query => {
    setLoadingUser(true);
    getUsers({ query, onlyActive: true })
      .then(({ data }) => {
        setUserOptions(
          data.data.map(user => ({
            value: user.id,
            label: `${user.id} - ${user.name}`,
          })),
        );
      })
      .catch(() => {
        // TODO: Alerta de erro ao realizar requisição
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, []);

  useEffect(() => {
    if (open) {
      fetchUsers();
      setUser(null);
      setErrors(null);
    }
  }, [fetchUsers, open]);

  const handleConfirm = () => {
    const permission = {
      user: user,
    };

    validateSchema
      .validate(permission, { abortEarly: false })
      .then(() => {
        onConfirm(user);
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Vincular usuário</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-lg-12">
            <Autocomplete
              options={userOptions}
              getOptionLabel={option => option.label}
              loading={loadingUser}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              renderInput={params => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Usuário"
                  margin="normal"
                  required
                  fullWidth
                  error={errors?.user ? true : false}
                  helperText={errors?.user} />
              )}
              onInputChange={(_, value) => {
                fetchUsers(value);
              }}
              onChange={(event, element) => {
                validateIfHasError("user", element);
                setUser(element);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
