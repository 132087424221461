import React from "react";
import { Alert, Box, Chip, Grid, Paper, Typography } from "@mui/material";
import {
  AccountBox,
  AccountTree,
  Assignment,
  DateRange,
  Extension,
  Feed,
  FormatListBulleted,
  Groups,
  MonitorHeart,
  People,
  PhoneIphone,
  Settings,
} from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "../../../../contexts/AuthContext";
import Calendar from "../Calendar";
import OrgChart from "../OrgChart";
import Employees from "../Employees";
import Managers from "../Managers";
import Registration from "../Registration";
import Health from "../Health";
import MobileUsers from "../MobileUsers";
import MobileRequests from "../MobileRequests";

function GridOption({ service, onChangeScreen }) {
  return (
    <Grid key={service.title} item xs={12} sm={6} lg={4} xl={2}>
      <Paper
        elevation={3}
        onClick={() => {
          if (!service.soon) onChangeScreen(service.screen);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "15px",
          position: "relative",
        }}
      >
        {service.soon && (
          <Box sx={{ position: "absolute", top: "5px", right: "5px" }}>
            <Chip label="Em breve" />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            ...(service.soon ? { opacity: 0.5 } : { cursor: "pointer" }),
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            {service.icon}
          </Box>
          <Typography variant="subtitle1">{service.title}</Typography>
        </Box>
      </Paper>
    </Grid>
  );
}

export default function Services() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();

  const screen = searchParams.get("screen");

  const hasGroup = groupName => {
    return user?.groups?.filter(group => group.name === groupName).length > 0;
  };

  const handleChangeScreen = screen => {
    searchParams.set("screen", screen);
    setSearchParams(searchParams);
  };

  const isAdmin = hasGroup("admin");

  const services = [
    {
      id: 1,
      title: "Cadastro",
      icon: <Settings />,
      context: "company",
      screen: "registration",
      soon: true,
    },
    {
      id: 2,
      title: "Calendário",
      icon: <DateRange />,
      context: "company",
      screen: "calendar",
      soon: false,
    },
    {
      id: 3,
      title: "Funcionários",
      icon: <Groups />,
      context: "company",
      screen: "employees",
      soon: false,
    },
    isAdmin && {
      id: 12,
      title: "Diagnóstico",
      icon: <MonitorHeart />,
      context: "company",
      screen: "health",
      soon: false,
    },
    {
      id: 4,
      title: "Gestores",
      icon: <People />,
      context: "company",
      screen: "managers",
      soon: false,
    },
    {
      id: 5,
      title: "Requisições",
      icon: <FormatListBulleted />,
      context: "app",
      screen: "mobile-requests",
      soon: false,
    },
    {
      id: 6,
      title: "Organograma",
      icon: <AccountTree />,
      context: "company",
      screen: "orgchart",
      soon: true,
    },
    {
      id: 7,
      title: "Usuários",
      icon: <PhoneIphone />,
      context: "app",
      screen: "mobile-users",
      soon: false,
    },
    {
      id: 8,
      title: "Mural de notícias",
      icon: <Feed />,
      context: "app",
      screen: "app-feed",
      soon: true,
    },
    {
      id: 9,
      title: "Usuários",
      icon: <AccountBox />,
      context: "portal",
      screen: "portal-users",
      soon: true,
    },
    {
      id: 10,
      title: "Kit admissional",
      icon: <Assignment />,
      context: "company",
      screen: "contracts",
      soon: true,
    },
    isAdmin && {
      id: 11,
      title: "Solicitações",
      icon: <Extension />,
      context: "portal",
      screen: "integrations",
      soon: true,
    },
  ];

  return (
    <Box
      sx={{
        padding: "25px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <Alert severity="warning">
        Algumas funcionalidades estão atualmente em modo experimental. Por
        favor, esteja ciente de possíveis instabilidades enquanto trabalhamos
        para aprimorá-las. Seu feedback é valorizado. Obrigado pela compreensão.
      </Alert>

      {screen === "calendar" && <Calendar />}
      {screen === "orgchart" && <OrgChart />}
      {screen === "employees" && <Employees />}
      {screen === "managers" && <Managers />}
      {screen === "registration" && <Registration />}
      {screen === "health" && isAdmin && <Health />}
      {screen === "mobile-users" && <MobileUsers />}
      {screen === "mobile-requests" && <MobileRequests />}

      <Typography variant="h5">Empresa</Typography>
      <Grid container spacing={2}>
        {services
          .filter(service => service.context === "company")
          .map(service => (
            <GridOption
              key={service.id}
              service={service}
              onChangeScreen={handleChangeScreen}
            />
          ))}
      </Grid>

      <Typography variant="h5">Portal</Typography>
      <Grid container spacing={2}>
        {services
          .filter(service => service.context === "portal")
          .map(service => (
            <GridOption
              key={service.id}
              service={service}
              onChangeScreen={handleChangeScreen}
            />
          ))}
      </Grid>

      <Typography variant="h5">Aplicativo</Typography>
      <Grid container spacing={2}>
        {services
          .filter(service => service.context === "app")
          .map(service => (
            <GridOption
              key={service.id}
              service={service}
              onChangeScreen={handleChangeScreen}
            />
          ))}
      </Grid>
    </Box>
  );
}
