import React, { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import "react-image-crop/dist/ReactCrop.css";

import useDebounceEffect from "../../../../../hooks/useDebounceEffect";
import { canvasPreview } from "../../../../../utils/canvasPreview";

export default function LogoDialog({ open, b64Photo, onConfirm }) {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [base64, setBase64] = useState("");
  const [completedCrop, setCompletedCrop] = useState();
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const b64 = await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        );

        setBase64(b64);
      }
    },
    100,
    [completedCrop],
  );

  return (
    <Dialog fullWidth width="xl" open={open}>
      <DialogContent>
        <ReactCrop
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
        >
          <img ref={imgRef} src={b64Photo} alt="company logo" />
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
        </ReactCrop>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onConfirm(base64)} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
