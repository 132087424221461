import React, { useState, useEffect } from "react";
import { Backdrop, Fab, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloudUpload, CreateNewFolder } from "@mui/icons-material";
import { saveAs } from "file-saver";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";

import { LoadingBox } from "../../../components/utils";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import {
  getDocument,
  downloadDocuments,
  storeFolder,
  moveDocumentToTrash,
  moveDocument,
  getDocuments,
  updateDocument,
} from "../../services/vmApi";
import FileManagerContainer from "./components/FileManagerContainer";
import TryAgainOrGoBackDialog from "../../components/TryAgainOrGoBackDialog";
import humanFileSize from "../../utils/humanFileSize";
import { STORE_PERMISSION } from "./documentPermission";
import { useApp } from "../../contexts/AppContext";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { pushDocumentToUploadList } = useApp();

  const folderId = searchParams.get("folder_id") ?? 1;
  const page = searchParams.get("page") ?? 1;
  const safeCode = searchParams.get("safe_code");

  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [filePropertiesDialog, setFilePropertiesDialog] = useState({
    open: false,
    id: "",
    permissions: [],
    name: "",
    description: "",
    inheritPermission: false,
    safeCode: "",
  });
  const [documentViewerDialog, setDocumentViewerDialog] = useState({
    open: false,
  });
  const [newFolderDialog, setNewFolderDialog] = useState({
    open: false,
  });
  const [
    documentsLoadErrorDialogOpen,
    setDocumentsLoadErrorDialogOpen,
  ] = useState(false);
  const [
    documentsLoadErrorDialogMessage,
    setDocumentsLoadErrorDialogMessage,
  ] = useState("");

  const documentsQuery = useQuery(["documents", folderId, page], () => {
    return getDocuments({ parentId: folderId, pageIndex: page });
  });

  const permission = documentsQuery.data?.data?.parent?.permission;

  useEffect(() => {
    const parent = documentsQuery.data?.data?.parent;
    const fetchedDocuments = documentsQuery.data?.data?.data;

    const safeCode =
      parent?.safeCode ?? parent?.parents?.find(p => p.safeCode)?.safeCode;

    if (safeCode) {
      searchParams.set("safe_code", Number(safeCode).toString(16));
      setSearchParams(searchParams);
    }

    if (fetchedDocuments) {
      setDocuments(documents => {
        return fetchedDocuments.map(
          ({ id, type, name, size, description, updatedAt, relatedTaskId }) => {
            const document = documents.find(d => d.id === id);

            return {
              id,
              type,
              name,
              size: size ? humanFileSize(size) : "",
              description,
              updatedAt: moment(updatedAt).format("DD/MM/YY HH:mm"),
              selected: document ? document.selected : false,
              currentSelected: document ? document.currentSelected : false,
              relatedTaskId,
            };
          },
        );
      });

      const parent = documentsQuery.data.data.parent;

      setBreadcrumbs(
        parent.parents
          .map(parent => {
            return {
              pathname: `/ecm?folder_id=${parent.id}`,
              title: parent.name,
            };
          })
          .concat([
            {
              pathname: `/ecm?folder_id=${parent.id}`,
              title: parent.name,
            },
          ]),
      );

      setMaxPages(documentsQuery.data.data.meta.last_page);
    }
  }, [documentsQuery.data, searchParams, setSearchParams]);

  const handleFMDoubleClick = currentSelectDoc => {
    if (currentSelectDoc.type === "dir") {
      navigate(`/ecm?folder_id=${currentSelectDoc.id}`);
    } else if (currentSelectDoc.type === "file") {
      setLoadingBackdrop(true);
      getDocument(currentSelectDoc.id)
        .then(({ data }) => {
          setDocumentViewerDialog({ open: true, data: data.data });
        })
        .catch(() => {
          setDocumentsLoadErrorDialogMessage(
            `Houve uma falha ao tentar buscar o documento em nossa base de dados, verifique se 
            seu computador está conectado a internet e tente novamente, caso persista entre em 
            contato com o suporte.`,
          );
          setDocumentsLoadErrorDialogOpen(true);
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    }
  };

  const handleFilePropertiesDialog = (selectedDoc, options) => {
    if (options.open) {
      setLoadingBackdrop(true);
      getDocument(selectedDoc.id)
        .then(({ data: { data } }) => {
          setFilePropertiesDialog({
            open: true,
            id: selectedDoc.id,
            permissions: data.permissions,
            name: data.name,
            description: data.description ?? "",
            inheritPermission: data.inheritPermission,
            safeCode: data.safeCode ?? "",
          });
        })
        .catch(() => {
          setDocumentsLoadErrorDialogMessage(
            `Houve uma falha ao tentar buscar o documento em nossa base de dados, verifique se 
            seu computador está conectado a internet e tente novamente, caso persista entre em 
            contato com o suporte.`,
          );
          setDocumentsLoadErrorDialogOpen(true);
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    } else {
      setFilePropertiesDialog({
        open: false,
        id: "",
        permissions: [],
        name: "",
        description: "",
        inheritPermission: false,
        safeCode: "",
      });
    }
  };

  const handleDocumentViewerDialog = options => {
    setDocumentViewerDialog(options);
  };

  const handleNewFolderDialog = options => {
    setNewFolderDialog(options);
  };

  const handleDownload = selectedDocs => {
    const documentIds = selectedDocs.map(document => document.id);
    /*const sync =
      documentIds.length === 1 &&
      !selectedDocs.find(document => document.type === "dir");*/
    const sync = true;

    setLoadingBackdrop(true);
    downloadDocuments({
      documentIds,
      sync,
    })
      .then(response => {
        if (response.data instanceof Blob) {
          saveAs(response.data, selectedDocs[0].name);
        }
      })
      .catch(() => {
        setDocumentsLoadErrorDialogMessage(
          `Houve uma falha ao tentar buscar o documento em nossa base de dados, verifique se 
          seu computador está conectado a internet e tente novamente, caso persista entre em 
          contato com o suporte.`,
        );
        setDocumentsLoadErrorDialogOpen(true);
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const handleDelete = selectedDocs => {
    setLoadingBackdrop(true);
    moveDocumentToTrash(selectedDocs.map(doc => doc.id))
      .then(() => {
        queryClient.invalidateQueries("documents");
      })
      .catch(() => {
        setDocumentsLoadErrorDialogMessage(
          `Houve uma falha ao tentar excluir o documento, verifique se seu computador está conectado 
          a internet e tente novamente, caso persista entre em contato com o suporte.`,
        );
        setDocumentsLoadErrorDialogOpen(true);
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const handleAddNewFolder = folder => {
    setLoadingBackdrop(true);
    storeFolder({
      relativePath: folder.name,
      parentId: folderId,
      template: folder.template,
    })
      .then(() => {
        queryClient.invalidateQueries("documents");
      })
      .catch(() => {
        setDocumentsLoadErrorDialogMessage(
          `Houve uma falha ao tentar criar a pasta, verifique se seu computador está conectado 
          a internet e tente novamente, caso persista entre em contato com o suporte.`,
        );
        setDocumentsLoadErrorDialogOpen(true);
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const handleMoveDocument = (files, targetFolderId) => {
    setLoadingBackdrop(true);
    moveDocument(targetFolderId, files)
      .then(() => {
        setDocuments(documents =>
          documents.filter(document => {
            return !files.includes(document.id);
          }),
        );

        queryClient.invalidateQueries("documents");
      })
      .catch(() => {
        setDocumentsLoadErrorDialogMessage(
          `Houve uma falha ao tentar movimentar o documento, verifique se seu computador está conectado 
          a internet e tente novamente, caso persista entre em contato com o suporte.`,
        );
        setDocumentsLoadErrorDialogOpen(true);
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const handleUpdateFileProperties = fileProperties => {
    setLoadingBackdrop(true);
    updateDocument(fileProperties.id, {
      name: fileProperties.name,
      description: fileProperties.description,
      inheritPermission: fileProperties.inheritPermission,
      permissions: fileProperties.permissions,
      safeCode: fileProperties.safeCode,
    })
      .then(() => {
        queryClient.invalidateQueries("documents");
      })
      .catch(() => {
        setDocumentsLoadErrorDialogMessage(
          `Houve uma falha ao tentar realizar o update do documento, verifique se seu computador está conectado 
        a internet e tente novamente, caso persista entre em contato com o suporte.`,
        );
        setDocumentsLoadErrorDialogOpen(true);
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const handlePushDocumentInUploadList = doc => {
    let canExec = true;

    /*if (safeCode) {
      const safeCodePrompt = prompt("Digite o código de segurança");

      if (Number(safeCodePrompt) !== parseInt(safeCode, 16)) {
        canExec = false;

        alert("Código de segurança");
      }
    }*/

    if (canExec) {
      if (
        !document.getElementById("uploadQueueDrop").classList.contains("show")
      ) {
        document.getElementById("uploadQueueBtn").click();
      }

      pushDocumentToUploadList(doc);
    }
  };

  const handleFileChange = event => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      handlePushDocumentInUploadList({
        id: uuidv4(),
        path: file.name,
        processing: false,
        finished: false,
        success: false,
        data: file,
        parentId: folderId,
      });
    }
  };

  const handleCloseDocumentLoadErrorDialog = () => {
    setDocumentsLoadErrorDialogOpen(false);
  };

  const handleTryAgainErrorDialog = () => {
    /* eslint-disable-next-line */
    location.reload();
  };

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loadingBackdrop}>
        <LoadingBox />
      </Backdrop>

      <TryAgainOrGoBackDialog
        open={documentsLoadErrorDialogOpen}
        onClose={handleCloseDocumentLoadErrorDialog}
        onGoBackClick={handleCloseDocumentLoadErrorDialog}
        onTryAgainClick={handleTryAgainErrorDialog}
        message={documentsLoadErrorDialogMessage}
      />

      <Card>
        <CardBody>
          <FileManagerContainer
            folderId={folderId}
            docs={documents}
            setDocs={setDocuments}
            breadcrumbs={breadcrumbs}
            page={currentPage}
            maxPages={maxPages}
            loading={documentsQuery.isLoading}
            onDoubleClick={handleFMDoubleClick}
            onPushDocumentInUploadList={handlePushDocumentInUploadList}
            filePropertiesDialog={filePropertiesDialog}
            handleFilePropertiesDialog={handleFilePropertiesDialog}
            documentViewerDialog={documentViewerDialog}
            handleDocumentViewerDialog={handleDocumentViewerDialog}
            newFolderDialog={newFolderDialog}
            handleNewFolderDialog={handleNewFolderDialog}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onAddNewFolder={handleAddNewFolder}
            onMoveDocument={handleMoveDocument}
            onUpdateFileProperties={handleUpdateFileProperties}
            permission={permission}
          />
        </CardBody>
      </Card>

      {permission >= STORE_PERMISSION && (
        <Box className={classes.fab}>
          <Fab
            color="primary"
            aria-label="nova pasta"
            onClick={() => handleNewFolderDialog({ open: true })}
          >
            <CreateNewFolder />
          </Fab>
          <div>
            <input
              type="file"
              accept=".doc, .pdf, .docx, .zip, .png, .xlsx, .xls, .jpg, .jpeg, .txt, .csv"
              id="fileInput"
              style={{ display: "none" }}
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput">
              <Fab
                color="secondary"
                aria-label="upload arquivos"
                component="span"
              >
                <CloudUpload />
              </Fab>
            </label>
          </div>
        </Box>
      )}
    </>
  );
};
