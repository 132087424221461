export default async function fileToBase64(file) {
  const reader = new FileReader();

  const b64Image = await new Promise(resolve => {
    reader.onload = ev => {
      resolve(ev.target.result);
    };
    reader.readAsDataURL(file);
  });

  return b64Image;
}
