import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import MaskedInput from "react-text-mask";

export const CpfMask = forwardRef(function CpfMask(props, ref) {
  return (
    <MaskedInput
      {...props}
      ref={ref.inputElement}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      guide={false}
      keepCharPositions={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
});

export default function CpfTextField(props) {
  return (
    <TextField
      variant="standard"
      {...props}
      InputProps={{
        ...props.InputProps,
        inputComponent: CpfMask,
      }}
    />
  );
}
