import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import {
  approveRequest,
  rejectRequest,
} from "../../../../../services/mobileApi";
import VacationForm from "./VacationForm";
import AbsenceForm from "./AbsenceForm";
import { Close } from "@mui/icons-material";

const VACATION = 1;
const ABSENCE = 2;

const choiceTitle = option => {
  const map = {
    [VACATION]: "🏖️ Férias",
    [ABSENCE]: "🤕 Afastamento",
  };

  return map[option];
};

export function MobileRequestDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");

  let defaultValues = {
    id: data?.id,
    enrollNumber: data?.enrollNumber ?? "",
    name: data?.name ?? "",
  };

  if (data?.type === VACATION) {
    defaultValues = {
      ...defaultValues,
      startDate: data.requestData.start_date ?? "",
      numberOfDays: data.requestData.number_of_days ?? "",
      hasAdvance13: data.requestData.has_advance_13,
      hasLoan: data.requestData.has_loan,
      loan: data.requestData.loan ?? "",
      hasAbatementPeriod: data.requestData.has_abatement_period,
      abatementPeriod: data.requestData.abatement_period,
      abatementDays: data.requestData.abatement_days ?? "",
    };
  } else if (data?.type === ABSENCE) {
    defaultValues = {
      ...defaultValues,
      absenceCode: data.requestData.absence_code ?? "",
      startDate: data.requestData.start_date ?? "",
      hasReturn: data.requestData.has_return ?? "",
      numberOfDays: data.requestData.number_of_days ?? "",
      justify: data.requestData.justify ?? "",
      attachments: data.requestData.attachments ?? [],
      doctorType: data.requestData.doctor_type ?? "",
      cid: data.requestData.cid ?? "",
      registrationUf: data.requestData.registration_uf ?? "",
      registrationCode: data.requestData.registration_code ?? "",
    };
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  const approveRequestMutation = useMutation(
    variables => {
      const { id, ...restVariables } = variables;

      return approveRequest(id, restVariables);
    },
    {
      onSuccess: () => {
        toast.success("Solicitação aprovada com sucesso.");
        queryClient.invalidateQueries("mobile-requests");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao aprovar solicitação.");
      },
    },
  );

  const rejectRequestMutation = useMutation(
    variables => {
      const { id, ...restVariables } = variables;

      return rejectRequest(id, restVariables);
    },
    {
      onSuccess: () => {
        toast.success("Solicitação rejeitada com sucesso.");
        queryClient.invalidateQueries("mobile-requests");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao rejeitar solicitação.");
      },
    },
  );

  const onApprove = data => {
    let { id } = data;

    const ok = window.confirm(
      "Tem certeza que deseja aprovar esta solicitação?",
    );

    if (ok) {
      approveRequestMutation.mutate({
        companyCode: companyCode.slice(0, 3),
        subsidiaryCode: companyCode.slice(3, 7),
        id,
      });
    }
  };

  const onReject = data => {
    let { id } = data;

    const ok = window.confirm(
      "Tem certeza que deseja rejeitar esta solicitação?",
    );

    if (ok) {
      rejectRequestMutation.mutate({
        companyCode: companyCode.slice(0, 3),
        subsidiaryCode: companyCode.slice(3, 7),
        id,
      });
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {choiceTitle(data?.type)} - Solicitações aplicativo
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  disabled
                  label="Matrícula"
                  margin="normal"
                  fullWidth
                  name="enrollNumber"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.enrollNumber ? true : false}
                  helperText={errors?.enrollNumber?.message}
                  onChange={onChange}
                />
              )}
              name="enrollNumber"
            />
          </div>
          <div className="col-md-8">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  disabled
                  label="Nome"
                  margin="normal"
                  fullWidth
                  name="name"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.name ? true : false}
                  helperText={errors?.name?.message}
                  onChange={onChange}
                />
              )}
              name="name"
            />
          </div>
        </div>
        {data?.type === VACATION && (
          <VacationForm
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
          />
        )}
        {data?.type === ABSENCE && (
          <AbsenceForm
            control={control}
            setValue={setValue}
            errors={errors}
            watch={watch}
          />
        )}
      </DialogContent>
      <DialogActions hidden={data.status !== 1}>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Fechar
        </Button>
        <Button
          onClick={handleSubmit(onReject)}
          variant="contained"
          color="primary"
          sx={{ backgroundColor: "red" }}
        >
          Rejeitar
        </Button>
        <Button
          onClick={handleSubmit(onApprove)}
          variant="contained"
          sx={{ backgroundColor: "green" }}
        >
          Aprovar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
