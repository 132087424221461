import React from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export default function TryAgainOrGoBackDialog({
  open,
  onClose,
  onGoBackClick,
  onTryAgainClick,
  message,
}) {
  return <>
    <Dialog disableEscapeKeyDown open={open} onClose={onClose}>
      <DialogTitle>{"Ops! Algo inesperado aconteceu..."}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onGoBackClick} color="inherit">
          Voltar
        </Button>
        <Button onClick={onTryAgainClick} color="inherit" autoFocus>
          Tentar novamente
        </Button>
      </DialogActions>
    </Dialog>
  </>;
}

TryAgainOrGoBackDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onGoBackClick: PropTypes.func,
  onTryAgainClick: PropTypes.func,
  message: PropTypes.string,
};
