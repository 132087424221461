import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Delete, CloudDownload, Settings } from "@mui/icons-material";
import PropTypes from "prop-types";
import { TOTAL_PERMISSION, UPDATE_PERMISSION } from "../documentPermission";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    width: 200,
    zIndex: "2147483647",
  },
  list: {
    padding: "1px !important",
  },
  item: {
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#e8f0fe",
    },
  },
  divider: {
    borderBottom: "1px solid #eee",
    margin: "10px 0",
  },
});

export default function ContextMenu({
  show,
  x,
  y,
  onDownload,
  onDelete,
  onProperties,
  permission,
}) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ display: show ? "block" : "none", top: y, left: x }}
    >
      <List className={classes.list}>
        <ListItem className={classes.item} onClick={onDownload}>
          <ListItemIcon>
            <CloudDownload />
          </ListItemIcon>
          <ListItemText primary="Fazer download" />
        </ListItem>
        {permission >= TOTAL_PERMISSION && (
          <ListItem className={classes.item} onClick={onDelete}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText primary="Excluir" />
          </ListItem>
        )}
        {permission >= UPDATE_PERMISSION && (
          <ListItem className={classes.item} onClick={onProperties}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Propriedades" />
          </ListItem>
        )}
      </List>
    </div>
  );
}

ContextMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onProperties: PropTypes.func.isRequired,
};
