const WAITING = 1;
const APPROVED = 2;
const REJECTED = 3;
const CANCELED = 4;

export default function getRequestStatus(type) {
  switch (type) {
    case WAITING:
      return "Aguardando aprovação";
    case APPROVED:
      return "Aprovado";
    case REJECTED:
      return "Rejeitado";
    case CANCELED:
      return "Cancelado";
    default:
      return "Desconhecido";
  }
}
