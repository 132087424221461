import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "@mui/material";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../contexts/AuthContext";

const validateSchema = object().shape({
  name: string().required(),
  template: string().nullable(),
});

export default function AddFolderDialog({ onClose, onConfirm }) {
  const { user } = useAuth();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      name: "",
      template: "1",
    },
  });

  const isAdmin = user?.groups?.map(group => group.name)?.includes("admin");

  return (
    <Dialog
      open
      maxWidth="xs"
      fullWidth
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>Nova pasta</DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  label="Nome"
                  margin="normal"
                  fullWidth
                  name="name"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.name ? true : false}
                  helperText={errors?.name?.message}
                  onChange={onChange}
                />
              )}
              name="name"
            />
          </div>
        </div>
        {isAdmin && (
          <div className="row">
            <div className="col-md-12">
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    label="Template"
                    margin="normal"
                    fullWidth
                    select
                    required
                    name="template"
                    variant="standard"
                    value={value}
                    onBlur={onBlur}
                    error={errors?.template ? true : false}
                    helperText={errors?.template?.message}
                    onChange={onChange}
                  >
                    <MenuItem value={"1"}>Nenhum</MenuItem>
                    <MenuItem value={"2"}>Nova filial</MenuItem>
                  </TextField>
                )}
                name="template"
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onConfirm)}
          variant="contained"
          color="primary"
        >
          Inserir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
