import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { useAuth } from "../../../contexts/AuthContext";

export default function Logout() {
  const { token, logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  return token ? <LayoutSplashScreen /> : <Navigate to="/auth/login" />;
}
