import React from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { getTableHealth } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import HealthTable from "./components/HealthTable";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Health() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");

  const statusQuery = useQuery(
    ["table-status", companyCode],
    () => getTableHealth(companyCode.slice(0, 3), companyCode.slice(3, 7)),
    {
      enabled: !!companyCode,
    },
  );

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const tables = statusQuery?.data?.data ?? [];

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Diagnóstico
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={statusQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <HealthTable data={tables} />
      </Box>
    </Dialog>
  );
}
