import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useEffect } from "react";
import { saveAs } from "file-saver";
import { PictureAsPdf } from "@mui/icons-material";

const absenceCodeTypes = [
  {
    description: "Afastamento por doença",
    id: "004",
    hasCid: true,
    hasDoctor: true,
  },
  {
    description: "Licença maternidade",
    id: "006",
    hasCid: true,
    hasDoctor: false,
  },
  {
    description: "Licença paternidade",
    id: "026",
    hasCid: true,
    hasDoctor: false,
  },
  {
    description: "Licença por acidente de trabalho",
    id: "003",
    hasCid: true,
    hasDoctor: true,
  },
  {
    description: "Licença por motivo de falecimento de familiar",
    id: "024",
    hasCid: false,
    hasDoctor: false,
  },
  {
    description: "Licença para casamento",
    id: "023",
    hasCid: false,
    hasDoctor: false,
  },
  {
    description: "Licença para doação de sangue",
    id: "022",
    hasCid: false,
    hasDoctor: false,
  },
];

const doctorTypes = [
  {
    description: "Médico",
    id: "1",
  },
  {
    description: "Médico Intercambista",
    id: "2",
  },
  {
    description: "Dentista",
    id: "3",
  },
];

export default function AbsenceForm({ setValue, control, errors, watch }) {
  const s3Url = "https://app-exe-attachments.s3.sa-east-1.amazonaws.com";

  const hasReturn = watch("hasReturn");
  const attachments = watch("attachments");

  useEffect(() => {
    if (hasReturn === "2") {
      setValue("numberOfDays", "");
    }
  }, [hasReturn, setValue]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Motivo do afastamento"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.absenceCode ? true : false}
                helperText={errors?.absenceCode?.message}
                onChange={onChange}
              >
                {absenceCodeTypes.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.description}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="absenceCode"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="Início do afastamento"
                type="date"
                margin="normal"
                fullWidth
                name="startDate"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.startDate ? true : false}
                helperText={errors?.startDate?.message}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            name="startDate"
          />
        </div>
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Possui retorno"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.hasReturn ? true : false}
                helperText={errors?.hasReturn?.message}
                onChange={onChange}
              >
                <MenuItem value={"1"}>Sim</MenuItem>
                <MenuItem value={"2"}>Não</MenuItem>
              </TextField>
            )}
            name="hasReturn"
          />
        </div>
        {hasReturn === "1" && (
          <div className="col-md-4">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  disabled
                  label="Quantidade de dias"
                  type="number"
                  margin="normal"
                  fullWidth
                  name="numberOfDays"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.numberOfDays ? true : false}
                  helperText={errors?.numberOfDays?.message}
                  onChange={onChange}
                />
              )}
              name="numberOfDays"
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                select
                label="Tipo profissional"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.doctorType ? true : false}
                helperText={errors?.doctorType?.message}
                onChange={onChange}
              >
                {doctorTypes.map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.description}
                  </MenuItem>
                ))}
              </TextField>
            )}
            name="doctorType"
          />
        </div>
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="CRM"
                margin="normal"
                fullWidth
                name="registrationCode"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.registrationCode ? true : false}
                helperText={errors?.registrationCode?.message}
                onChange={onChange}
              />
            )}
            name="registrationCode"
          />
        </div>
        <div className="col-md-4">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="UF CRM"
                margin="normal"
                fullWidth
                name="registrationUf"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.registrationUf ? true : false}
                helperText={errors?.registrationUf?.message}
              />
            )}
            name="registrationUf"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                required
                disabled
                label="CID"
                margin="normal"
                fullWidth
                name="cid"
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.cid ? true : false}
                helperText={errors?.cid?.message}
                onChange={onChange}
              />
            )}
            name="cid"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                disabled
                label="Justificativa"
                margin="normal"
                fullWidth
                variant="standard"
                value={value}
                onBlur={onBlur}
                error={errors?.justify ? true : false}
                helperText={errors?.justify?.message}
                onChange={onChange}
              />
            )}
            name="justify"
          />
        </div>
      </div>

      <Box sx={{ display: "flex", gap: "10px" }}>
        {attachments.map((attachment, index) => {
          return (
            <Box
              key={attachment}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  backgroundColor: "#3C8DBC",
                  borderRadius: "15px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => saveAs(`${s3Url}/${attachment}`)}
              >
                {attachment.includes("pdf") ? (
                  <PictureAsPdf fontSize="large" sx={{ color: "#FFFFFF" }} />
                ) : (
                  <img
                    src={`${s3Url}/${attachment}`}
                    alt="foto atestado"
                    style={{ width: "100px", height: "100px" }}
                  />
                )}
              </Box>
              <Typography variant="caption">Anexo {index + 1}</Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
