/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef,no-sequences */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { object, string, ref } from "yup";

import { toAbsoluteUrl } from "../../../../_helpers";
import { changePassword } from "../../../../../app/services/vmApi";
import { useAuth } from "../../../../../app/contexts/AuthContext";

export function QuickUser() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [errors, setErrors] = useState(null);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(
    false,
  );

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    navigate("/logout");
  };

  const validateSchema = object({
    password: string()
      .nullable()
      .required(),
    newPassword: string()
      .min(8, "A nova senha deve ter no mínimo 8 caracteres")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~])[A-Za-z!@#$%^&*()_+{}[\]:;<>,.?~\d]{8,}$/,
        "É necessário no mínimo 1 caractere especial e 1 letra maiúscula",
      )
      .required()
      .nullable(),
    repeatNewPassword: string()
      .oneOf([ref("newPassword")], "Senhas não são iguais")
      .nullable()
      .required(),
  });

  const handleRenewPassword = () => {
    const trocarSenha = {
      password: password,
      newPassword: newPassword,
      repeatNewPassword: repeatNewPassword,
    };

    validateSchema
      .validate(trocarSenha, { abortEarly: false })
      .then(() => {
        changePassword({ password, newPassword })
          .then(() => {
            setPassword("");
            setNewPassword("");
            setRepeatNewPassword("");
            setErrors({});
            setChangePasswordDialogOpen(false);
            toast.success(`Senha alterada com sucesso.`);
          })
          .catch(err => {
            setErrors({
              password: err.response.data.message,
            });
          });
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  useEffect(() => {
    if (changePasswordDialogOpen) {
      setPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
      setErrors({});
    }
  }, [changePasswordDialogOpen]);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Perfil do usuário</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <span className="symbol symbol-100 symbol-light-success mr-5">
            <span className="symbol-label font-size-h1 font-weight-bold">
              {user?.name?.[0]?.toUpperCase()}
            </span>
          </span>

          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user?.name}
            </a>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg",
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user?.email}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Dialog
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                onClose(event, reason);
              }
            }}
            disableEscapeKeyDown
            open={changePasswordDialogOpen}
          >
            <DialogTitle>Trocar senha</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Senha atual"
                  margin="dense"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={event => {
                    setPassword(event.target.value);
                  }}
                  error={errors?.password ? true : false}
                  helperText={errors?.password}
                />
                <TextField
                  label="Nova senha"
                  margin="dense"
                  variant="outlined"
                  type="password"
                  value={newPassword}
                  onChange={event => {
                    setNewPassword(event.target.value);
                  }}
                  error={errors?.newPassword ? true : false}
                  helperText={errors?.newPassword}
                />
                <TextField
                  label="Repita a nova senha"
                  margin="dense"
                  variant="outlined"
                  type="password"
                  value={repeatNewPassword}
                  onChange={event => {
                    setRepeatNewPassword(event.target.value);
                  }}
                  error={errors?.repeatNewPassword ? true : false}
                  helperText={errors?.repeatNewPassword}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setChangePasswordDialogOpen(false)}
                color="inherit"
              >
                Cancelar
              </Button>
              <Button onClick={handleRenewPassword} color="inherit" autoFocus>
                Atualizar
              </Button>
            </DialogActions>
          </Dialog>
          <button
            className="btn btn-success btn-bold"
            onClick={() => setChangePasswordDialogOpen(true)}
          >
            Trocar senha
          </button>

          <button className="btn btn-primary btn-bold" onClick={logoutClick}>
            Sair
          </button>
        </div>
      </div>
    </div>
  );
}
