/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Paper } from "@mui/material";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { storeDocument } from "../../../../../app/services/vmApi";
import { useQueryClient } from "react-query";
import { useApp } from "../../../../../app/contexts/AppContext";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function QuickActionsDropdown() {
  const { uploadList, setDocumentInUploadList } = useApp();

  const queryClient = useQueryClient();
  const bgImage = toAbsoluteUrl("/media/misc/bg-2.jpg");
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.quick-actions.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  useEffect(() => {
    if (uploadList.find(document => document.finished === false)) {
      window.onbeforeunload = function(event) {
        event.returnValue = "Are you sure you want to leave?";
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [uploadList]);

  useEffect(() => {
    uploadList
      .filter(document => {
        return !document.finished;
      })
      .slice(0, 1)
      .forEach(document => {
        if (!document.processing) {
          setDocumentInUploadList(document.id, {
            ...document,
            processing: true,
          });

          storeDocument(document.path, document.data, document.parentId)
            .then(() => {
              setDocumentInUploadList(document.id, {
                ...document,
                processing: false,
                finished: true,
                success: true,
              });

              queryClient.invalidateQueries("documents");
            })
            .catch(() => {
              setDocumentInUploadList(document.id, {
                ...document,
                processing: false,
                finished: false,
                success: false,
              });
            });
        }
      });
  }, [setDocumentInUploadList, queryClient, uploadList]);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="quick-actions-tooltip">Fila de uploads</Tooltip>
          }
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1"
              id="kt_quick_actions_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight id="uploadQueueDrop">
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_actions_panel_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="quick-actions-tooltip">Fila de uploads</Tooltip>
              }
            >
              <div
                id="uploadQueueBtn"
                className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Cloud-upload.svg",
                    )}
                  />
                </span>
                {uploadList.length > 0 && <span className="pulse-ring"></span>}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Fila de uploads</span>
                </h4>

                <Paper className="mt-5">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="navi navi-hover scroll my-4"
                    style={{ maxHeight: "300px", position: "relative" }}
                  >
                    {uploadList.length === 0 && (
                      <div
                        style={{
                          flex: "1 1 0%",
                          textAlign: "center",
                          height: 75,
                        }}
                      >
                        <span>Nenhum arquivo na fila</span>
                      </div>
                    )}
                    {uploadList.map((document, index) => (
                      <a href="#" className="navi-item" key={index}>
                        <div className="navi-link">
                          <div className="navi-icon mr-5">
                            {!document.finished && (
                              <Spinner animation="border" variant="secondary" />
                            )}
                            {document.finished && document.success && (
                              <i className="flaticon2-check-mark text-success"></i>
                            )}
                            {document.finished && !document.success && (
                              <i className="flaticon2-cross text-danger"></i>
                            )}
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">
                              {document.path}
                            </div>
                            <div className="text-muted"></div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </PerfectScrollbar>
                </Paper>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
