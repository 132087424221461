import React, { useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { getManagers } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import ManagerTable from "./components/ManagerTable";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Managers() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  const companyCode = searchParams.get("company_code");

  const tablePageIndex = Number(searchParams.get("page") ?? 1);
  const tablePageSize = Number(searchParams.get("page_size") ?? 50);

  const managerQuery = useQuery(
    ["managers", companyCode, tablePageIndex, tablePageSize, searchQuery],
    () =>
      getManagers(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        searchQuery,
        {
          allSubsidiaries: true,
        },
      ),
    {
      enabled: !!companyCode,
    },
  );

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const searchDelayed = debounce(query => {
    setSearchQuery(query);
  }, 1000);

  const managers = managerQuery?.data?.data?.data ?? [];
  const rows = managerQuery?.data?.data?.meta?.total ?? 0;

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Gestores
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={managerQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              searchDelayed(event.target.value);
            }}
          />
        </Box>
        <ManagerTable data={managers} rows={rows} />
      </Box>
    </Dialog>
  );
}
