import React, { useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { LoadingBox } from "../../../../../components/utils";
import MobileRequestTable from "./components/MobileRequestTable";
import { getRequest, getRequests } from "../../../../services/mobileApi";
import { MobileRequestDialog } from "./components/MobileRequestDialog";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileRequests() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const companyCode = searchParams.get("company_code");
  const requestId = searchParams.get("request_id");

  const tablePageIndex = Number(searchParams.get("page") ?? 1);
  const tablePageSize = Number(searchParams.get("page_size") ?? 50);

  const requestsQuery = useQuery(
    [
      "mobile-requests",
      companyCode,
      tablePageIndex,
      tablePageSize,
      searchQuery,
    ],
    () =>
      getRequests({
        company_code: companyCode.slice(0, 3),
        subsidiary_code: companyCode.slice(3, 7),
      }),
    {
      enabled: !!companyCode,
    },
  );

  const requestQuery = useQuery(
    ["mobile-requests", requestId],
    () =>
      getRequest(companyCode.slice(0, 3), companyCode.slice(3, 7), requestId),
    {
      enabled: !!requestId,
      onSuccess: () => setDialogOpen(true),
    },
  );
  let request = requestQuery.data?.data?.data;
  request = request
    ? {
        id: request.id,
        type: request.type,
        status: request.status,
        enrollNumber: request.created_by?.info?.[0]?.data
          ? JSON.parse(request.created_by.info[0].data)?.enroll_number
          : "",
        name: request.created_by?.info?.[0]?.name ?? "Desconhecido",
        requestData: request?.request_data
          ? JSON.parse(request?.request_data)
          : null,
      }
    : null;

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const handleCloseRequestDialog = () => {
    searchParams.delete("request_id");
    setSearchParams(searchParams);

    setDialogOpen(false);
  };

  const searchDelayed = debounce(query => {
    setSearchQuery(query);
  }, 1000);

  const requests = requestsQuery?.data?.data?.data ?? [];
  const rows = requestsQuery?.data?.data?.meta?.total ?? 0;

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {dialogOpen && (
        <MobileRequestDialog
          onClose={handleCloseRequestDialog}
          data={request}
        />
      )}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Solicitações aplicativo
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={requestsQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              searchDelayed(event.target.value);
            }}
          />
        </Box>
        <MobileRequestTable data={requests} rows={rows} />
      </Box>
    </Dialog>
  );
}
