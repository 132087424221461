/* eslint-disable no-unused-expressions */
import React, { forwardRef, useEffect, useState } from "react";
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { saveAs } from "file-saver";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  loading: {
    flex: 1,
    textAlign: "center",
    marginTop: 50,
  },
  frame: {
    width: "100%",
    height: "100%",
  },
}));

export default function DocumentViewerDialog({ name, url, open, onClose }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const interval = setInterval(() => {
      if (open) {
        const iframeViewer = document.getElementById("iframe-viewer");

        if (iframeViewer) {
          try {
            iframeViewer.contentWindow.document.body.scrollHeight;
            iframeViewer.src += "";
          } catch (e) {
            setLoading(false);
            clearInterval(interval);
          }
        }
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [url, open]);

  const handleSrcViewer = (name, url) => {
    if (name)
      switch (name.substr(name.lastIndexOf(".") + 1).toUpperCase()) {
        case "JPEG":
        case "JPG":
        case "PNG":
        case "GIF":
        case "TIFF":
        case "BMP":
          return url;
        default:
          return `https://docs.google.com/viewer?embedded=true&url=${url}`;
      }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="Close"
            size="large"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {name}
          </Typography>
          <Button color="inherit" onClick={() => saveAs(url, name)}>
            download
          </Button>
          <Button color="inherit" onClick={onClose}>
            fechar
          </Button>
        </Toolbar>
      </AppBar>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
          <h3>Carregando...</h3>
        </div>
      )}
      <iframe
        id="iframe-viewer"
        title={name}
        src={handleSrcViewer(name, url)}
        frameBorder="no"
        className={classes.frame}
        style={{ display: loading ? "none" : undefined }}
      ></iframe>
    </Dialog>
  );
}
