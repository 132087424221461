import React, { useEffect, useState } from "react";
import {
  AppBar,
  Backdrop,
  Dialog,
  IconButton,
  Box,
  Slide,
  Toolbar,
  Typography,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { AttachFile, Close } from "@mui/icons-material";
import { makeStyles, styled } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useQuery } from "react-query";

import { LoadingBox } from "../../../../../components/utils";
import { getCompany } from "../../../../services/pwsApi";
import LogoDialog from "./components/LogoDialog";
import fileToBase64 from "../../../../utils/fileToBase64";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validateSchema = object().shape({
  companyCode: string().required(),
  companyDescription: string().required(),
  subsidiaryCode: string().required(),
  subsidiaryDescription: string().required(),
  subsidiaryCnpj: string().required(),
});

export default function Registration() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [logoToAdjust, setLogoToAdjust] = useState("");

  const companyCode = searchParams.get("company_code");

  const companyQuery = useQuery(["company", companyCode], () =>
    getCompany(companyCode.slice(0, 3), companyCode.slice(3, 7)),
  );

  const company = companyQuery.data?.data?.data;

  const { control, watch, reset, setValue } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      companyCode: "",
      companyDescription: "",
      subsidiaryLogoBase64: "",
      subsidiaryCode: "",
      subsidiaryDescription: "",
      companyCnpj: "",
      subsidiaryCnpj: "",
      subsidiaryFormalDescription: "",
      subsidiaryCep: "",
      subsidiaryStreet: "",
      subsidiaryCity: "",
      subsidiaryUf: "",
      subsidiaryState: "",
      subsidiaryComplement: "",
    },
  });

  const subsidiaryLogoBase64 = watch("subsidiaryLogoBase64");

  useEffect(() => {
    reset({
      companyCode: company?.APS_COD_EMPRESA ?? "",
      companyDescription: company?.APS_DESC_EMPRESA ?? "",
      subsidiaryCode: company?.APS_COD_FILIAL ?? "",
      subsidiaryDescription: company?.APS_DESC_FILIAL ?? "",
      subsidiaryCnpj: company?.APS_CNPJ_FILIAL ?? "",
      subsidiaryLogoBase64: company?.APS_LOGO_B64
        ? `data:image/jpeg;base64,${company?.APS_LOGO_B64}`
        : "",
    });
  }, [company, reset]);

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const handleLogoToBase64 = async file => {
    const base64 = await fileToBase64(file);

    setLogoToAdjust(base64);
  };

  const handleConfirmCropImage = b64 => {
    setValue("subsidiaryLogoBase64", b64);
    setLogoToAdjust("");
  };

  return (
    <>
      <LogoDialog
        open={logoToAdjust !== ""}
        b64Photo={logoToAdjust}
        onConfirm={handleConfirmCropImage}
      />
      <Dialog
        fullScreen
        open
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Registro
            </Typography>
          </Toolbar>
        </AppBar>
        <Backdrop className={classes.backdrop} open={companyQuery.isLoading}>
          <LoadingBox />
        </Backdrop>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            gap: "15px",
          }}
        >
          <Typography variant="h5">Logo</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <img src={subsidiaryLogoBase64} alt="logo da empresa" />
            <Button
              component="label"
              variant="contained"
              startIcon={<AttachFile />}
            >
              Subir imagem
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={e => {
                  if (e.currentTarget.files)
                    handleLogoToBase64(e.currentTarget.files[0]);
                }}
              />
            </Button>
          </Box>

          <Typography variant="h5">Empresa</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Código empresa"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCode"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Descrição facilitada empresa"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Código filial"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryCode"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Descrição facilitada filial"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="CNPJ Matriz"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCnpj"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="CNPJ Filial"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryCnpj"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Descrição formal filial"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Inscrição estadual"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
          </Grid>
          <Typography variant="h5">Endereço</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="CEP"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCode"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Endereço"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyDescription"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Complemento"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryDescription"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Bairro"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCnpj"
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="UF"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryCnpj"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Estado"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
          </Grid>
          <Typography variant="h5">Parametrização</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Código cliente"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCode"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Pasta admissão"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Pasta afastamento"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryCode"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Pasta alteração cadastral"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Pasta benefício"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="companyCnpj"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Primeira folha"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryCnpj"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Tipo matrícula eSocial"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Possui APP Gestor"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    label="Possui foto na ficha de registro"
                    variant="outlined"
                    disabled
                    size="small"
                  />
                )}
                name="subsidiaryFormalDescription"
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}
