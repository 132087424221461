import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { string, object, boolean } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Security } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";

import { storeUser, updateUser } from "../../../../../services/mobileApi";
import randPassword from "../../../../../utils/randPassword";
import CpfTextField from "../../../../../components/react-form/CpfTextField";
import isValidCpf from "../../../../../utils/isValidCpf";

export function MobileUserDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");

  const validateSchema = object().shape({
    enrollNumber: string().required(),
    cpf: string().test(
      "is-cpf",
      "The value does not match a valid CPF",
      value => isValidCpf(value),
    ),
    email: string()
      .email()
      .required(),
    password: string().required(),
    checked: boolean().required(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      id: data?.id,
      enrollNumber: data?.enrollNumber ?? "",
      cpf: data?.cpf ?? "",
      email: data?.email ?? "",
      password: !!data?.id ? "********" : "",
      checked: data?.checked ?? true,
    },
  });

  const storeUserMutation = useMutation(storeUser, {
    onSuccess: () => {
      toast.success("Usuário criado com sucesso.");
      queryClient.invalidateQueries("mobile-users");
      onClose();
    },
    onError: err => {
      toast.error(err?.response?.data?.message ?? "Erro ao criar usuário.");
    },
  });

  const updateUserMutation = useMutation(
    variables => {
      const { id, ...restVariables } = variables;

      return updateUser(id, restVariables);
    },
    {
      onSuccess: () => {
        toast.success("Usuário atualizado com sucesso.");
        queryClient.invalidateQueries("mobile-users");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao editar usuário.");
      },
    },
  );

  const onSubmit = data => {
    let { id, enrollNumber, cpf, email, password, checked } = data;

    if (id) {
      updateUserMutation.mutate({
        companyCode: companyCode.slice(0, 3),
        subsidiaryCode: companyCode.slice(3, 7),
        id,
        enrollNumber,
        cpf: cpf.match(/\d+/g).join(''),
        email,
        password: password === "********" ? undefined : password,
        checked,
      });
    } else {
      storeUserMutation.mutate({
        companyCode: companyCode.slice(0, 3),
        subsidiaryCode: companyCode.slice(3, 7),
        enrollNumber,
        cpf: cpf.match(/\d+/g).join(''),
        email,
        password,
        checked,
      });
    }
  };

  const handleGeneratePassword = () => {
    const generatedPassword = randPassword(5, 3, 2);

    setValue("password", generatedPassword);

    setShowPassword(true);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>
        {!data?.id ? `Adicionar` : `Editar`} usuário aplicativo
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  label="Matricula"
                  margin="normal"
                  fullWidth
                  name="enrollNumber"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.enrollNumber ? true : false}
                  helperText={errors?.enrollNumber?.message}
                  onChange={onChange}
                />
              )}
              name="enrollNumber"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <CpfTextField
                  required
                  label="CPF"
                  margin="normal"
                  fullWidth
                  name="cpf"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.cpf ? true : false}
                  helperText={errors?.cpf?.message}
                  onChange={onChange}
                />
              )}
              name="cpf"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  label="Email"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.email ? true : false}
                  helperText={errors?.email?.message}
                  onChange={onChange}
                />
              )}
              name="email"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type={showPassword ? `text` : `password`}
                  disabled={!showPassword}
                  label="Senha"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.password ? true : false}
                  helperText={errors?.password?.message}
                  onChange={onChange}
                />
              )}
              name="password"
            />
          </div>
          <div className="col-lg-5" style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: "4px", alignSelf: "flex-end" }}
              onClick={handleGeneratePassword}
            >
              <Security sx={{ marginRight: "4px", fontSize: "20px" }} />
              Gerar senha
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <FormControlLabel
                  control={<Switch />}
                  label="Email verificado"
                  checked={value}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  required
                />
              )}
              name="checked"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.id ? `Adicionar` : `Editar`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
