import React from "react";
import { Calendar as ReactCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import { v4 as uuidv4 } from "uuid";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "react-query";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getHolidays as vmGetHolidays } from "../../../../services/vmApi";
import { activeCalendar } from "../../../../services/vmApi";
import { Card, CardBody } from "../../../../../_metronic/_partials/controls";
import { LoadingBox } from "../../../../../components/utils";
import { getHolidays } from "../../../../services/pwsApi";
import { Close } from "@mui/icons-material";

const localizer = momentLocalizer(moment);

const messages = {
  allDay: "Dia Inteiro",
  previous: "<",
  next: ">",
  today: "Hoje",
  month: "Mês",
  week: "Semana",
  day: "Dia",
  agenda: "Agenda",
  date: "Data",
  time: "Hora",
  event: "Evento",
  showMore: total => `+ (${total}) Eventos`,
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Calendar() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");

  const calendarQuery = useQuery(
    ["active-calendar", companyCode],
    () => activeCalendar(companyCode),
    {
      enabled: !!companyCode,
      onSuccess: data => {
        if (!data?.data)
          toast.error("Não há um calendário vinculado a esta empresa.");
      },
      onError: () => {
        toast.error("Erro ao tentar carregar calendário.");
      },
    },
  );
  const calendar = calendarQuery?.data?.data?.data;

  const events =
    calendar?.events?.map(event => ({
      key: uuidv4(),
      id: event.id,
      title: event.name,
      start: moment(event.startDate, "YYYY-MM-DD").toDate(),
      end: moment(event.endDate, "YYYY-MM-DD").toDate(),
      color: event.responsible === 1 ? "#3174AD" : "#F39C12",
      allDay: true,
    })) ?? [];

  const holidaysQuery = useQuery(
    ["holidays", companyCode],
    () =>
      getHolidays({
        queryParams: {
          empresas: [companyCode],
        },
      }),
    {
      onError: () => {
        toast.error("Erro ao tentar carregar feriados.");
      },
    },
  );

  let holidays = holidaysQuery.data?.data ?? {};
  holidays = Object.values(holidays)
    .flat()
    .map(holiday => {
      let date = moment(holiday.DATA, "YYYYMMDD");
      if (holiday.P3_FIXO === "S") {
        date = moment(
          `${new Date().getFullYear()}${holiday.P3_MESDIA}`,
          "YYYYMMDD",
        );
      }

      return {
        key: uuidv4(),
        id: uuidv4(),
        title: holiday.P3_DESC.trim(),
        start: date.toDate(),
        end: date.toDate(),
        allDay: true,
        color: "#FF0000",
      };
    });

  const localHolidaysQuery = useQuery(
    "local-holidays",
    () => vmGetHolidays({ showOnCalendars: true }),
    {
      onError: () => {
        toast.error("Erro ao tentar carregar feriados locais.");
      },
    },
  );

  const localHolidays =
    localHolidaysQuery.data?.data?.data?.map(holiday => ({
      key: uuidv4(),
      id: uuidv4(),
      title: "Feriado local RH Digital",
      start: moment(holiday.date, "YYYY-MM-DD"),
      end: moment(holiday.date, "YYYY-MM-DD"),
      allDay: true,
      color: "#FF0000",
    })) ?? [];

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {calendar?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={calendarQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Card style={{ height: "100%" }}>
        <CardBody>
          <ReactCalendar
            messages={messages}
            localizer={localizer}
            events={events.concat(holidays).concat(localHolidays)}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={event => {
              return { style: { backgroundColor: event.color } };
            }}
            dayPropGetter={day => {
              if (day.getDay() === 0) {
                return {
                  style: {
                    backgroundColor: "#E6E6E6",
                  },
                };
              }
            }}
            components={{
              month: {
                header: props => {
                  const { date } = props;
                  const dayOfWeek = date.getDay();

                  const style =
                    dayOfWeek === 0
                      ? { color: "#999999" }
                      : { color: "#000000" };
                  return (
                    <Box component="span" sx={style}>
                      {props.label}
                    </Box>
                  );
                },
              },
            }}
          />
        </CardBody>
      </Card>
    </Dialog>
  );
}
