import React from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import {
  AppBar,
  Backdrop,
  Dialog,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { getOrgchart } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrgChat() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");

  const orgchartQuery = useQuery(
    ["orgchart", companyCode],
    () => getOrgchart(companyCode.slice(0, 3), companyCode.slice(3, 7)),
    {
      enabled: !!companyCode,
    },
  );

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const employees =
    orgchartQuery?.data?.data?.map(employee => {
      if (employee.gestor === employee.id) {
        return {
          ...employee,
          gestor: "0",
        };
      }

      return employee;
    }) ?? [];

  function buildUserTree(users, managerId) {
    const subordinates = users.filter(user => user.gestor === managerId);

    const tree = subordinates.map(subordinate => ({
      name: subordinate.nome,
      id: subordinate.id,
      title: subordinate.titulo,
      children: buildUserTree(users, subordinate.id),
    }));

    return tree;
  }

  function buildOrganizationalChart(users) {
    const ceo = users.find(user => user.gestor === "0");

    if (!ceo) return {};

    const organizationalChart = {
      name: ceo.nome,
      id: ceo.id,
      title: ceo.titulo,
      children: buildUserTree(users, ceo.id),
    };

    return organizationalChart;
  }

  const organizationalChart = buildOrganizationalChart(employees);
console.log(organizationalChart)
  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Organograma
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={orgchartQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Paper sx={{ height: "100%", padding: "15px" }}>
        <OrganizationChart datasource={organizationalChart} />
      </Paper>
    </Dialog>
  );
}
