import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import {
  Close,
  FormatListBulleted,
  ImageNotSupported,
  PhoneIphone,
  Search,
} from "@mui/icons-material";
import { useInfiniteQuery, useQuery } from "react-query";
import { Backdrop } from "@mui/material";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";

import { getCompanies } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import Services from "../Services";
import { useAuth } from "../../../../contexts/AuthContext";
import {
  getEmployeeStatistics,
  getRequestStatistics,
} from "../../../../services/mobileApi";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyCompany() {
  const classes = useStyles();

  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [companyQuery, setCompanyQuery] = useState("");
  const [titleCompanyDialog, setTitleCompanyDialog] = useState("");
  const [chooseCompanyDialog, setChooseCompanyDialog] = useState();

  const companyCode = searchParams.get("company_code");

  const hasGroup = groupName => {
    return user?.groups?.filter(group => group.name === groupName).length > 0;
  };

  const isAdmin = hasGroup("admin");

  const companiesQuery = useInfiniteQuery(
    ["companies", user.id, companyQuery],
    ({ pageParam = 1 }) => {
      return getCompanies(companyQuery, {
        page: pageParam,
        logo: true,
      });
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.data?.data?.length === 0) {
          return false;
        }

        return lastPage.data?.meta?.current_page + 1;
      },
      refetchOnWindowFocus: false,
    },
  );

  const companies =
    companiesQuery.data?.pages?.reduce((data, page) => {
      return [...data, ...page.data.data];
    }, []) ?? [];

  const adminRequestStatisticQuery = useQuery(
    ["admin-requests-statistics", user.id],
    getRequestStatistics,
    {
      enabled: companies.length > 0,
    },
  );

  const adminRequestStatisticTotal =
    adminRequestStatisticQuery?.data?.data?.meta?.total;
  const adminRequestStatistic = adminRequestStatisticQuery?.data?.data?.data?.map(
    request => {
      return {
        ...request,
        onClick: () => {
          setTitleCompanyDialog(request.description);

          setSearchParams({
            company_code: request.code,
            screen: "mobile-requests",
          });

          setChooseCompanyDialog(null);
        },
      };
    },
  );

  const employeeStatisticQuery = useQuery(
    ["employee-statistics", user.id],
    getEmployeeStatistics,
    {
      enabled: companies.length > 0,
    },
  );

  const employeeStatisticTotal =
    employeeStatisticQuery?.data?.data?.meta?.total;
  const employeeStatistic = employeeStatisticQuery?.data?.data?.data?.map(
    employee => {
      return {
        ...employee,
        onClick: () => {
          setTitleCompanyDialog(employee.description);

          setSearchParams({
            company_code: employee.code,
            screen: "mobile-users",
          });

          setChooseCompanyDialog(null);
        },
      };
    },
  );

  const leads = [
    /*{
      description: "Eventos hoje calendário",
      value: "4058",
      icon: <Event sx={{ width: "30px", height: "30px" }} />,
      onClick: event => {
        setAnchorEl(event.currentTarget);
        setChooseCompanyDialog(adminRequestStatistic);
      },
    },*/
    {
      description: "Requisições pendentes aplicativo",
      value: adminRequestStatisticTotal ?? (
        <LinearProgress sx={{ mt: "10px" }} />
      ),
      icon: <FormatListBulleted sx={{ width: "30px", height: "30px" }} />,
      onClick: event => {
        setAnchorEl(event.currentTarget);
        setChooseCompanyDialog(adminRequestStatistic);
      },
    },
    {
      description: "Usuários aplicativo",
      value: employeeStatisticTotal ?? <LinearProgress sx={{ mt: "10px" }} />,
      icon: <PhoneIphone sx={{ width: "30px", height: "30px" }} />,
      onClick: event => {
        setAnchorEl(event.currentTarget);
        setChooseCompanyDialog(employeeStatistic);
      },
    },
  ];

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: companiesQuery?.hasNextPage ?? false,
    onLoadMore: companiesQuery?.fetchNextPage,
    disabled: !!companiesQuery.error,
    rootMargin: "0px 0px 400px 0px",
  });

  const handleOpenCompany = (id, title) => {
    setTitleCompanyDialog(title);

    setSearchParams({ company_code: id });
  };

  const handleCloseCompany = () => {
    searchParams.delete("company_code");
    setSearchParams(searchParams);
  };

  const searchDelayed = debounce(query => {
    setCompanyQuery(query);
  }, 1000);

  const isLoading = companiesQuery?.isLoading;

  return (
    <>
      <Backdrop className={classes.backdrop} open={isLoading ?? true}>
        <LoadingBox />
      </Backdrop>

      <Dialog
        fullScreen
        open={!!companyCode}
        onClose={handleCloseCompany}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseCompany}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {titleCompanyDialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <Services />
      </Dialog>

      <Menu
        anchorEl={anchorEl}
        open={!!chooseCompanyDialog}
        onClose={() => setChooseCompanyDialog(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: "1.5px",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "14px",
              width: "10px",
              height: "10px",
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {chooseCompanyDialog?.map(data => (
          <MenuItem
            key={data.code}
            onClick={data.onClick}
            sx={{ display: "flex", alignContent: "center", gap: "7px" }}
          >
            <Box>
              {data.code} - {data.description}
            </Box>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
                color: "#FFFFFF",
              }}
            >
              {data.count}
            </Box>
          </MenuItem>
        ))}
      </Menu>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        {isAdmin && (
          <Grid container spacing={2}>
            {leads.map(lead => (
              <Grid
                key={lead.description}
                item
                xs={12}
                sm={6}
                md={4}
                onClick={lead.onClick}
              >
                <Card sx={{ cursor: "pointer" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: "10px",
                      height: "102px",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="subtitle1" noWrap>
                        {lead.description}
                      </Typography>
                      <Typography variant="h3">{lead.value}</Typography>
                    </Box>
                    <Avatar
                      sx={{ bgcolor: "#F59D3A", width: "56px", height: "56px" }}
                    >
                      {lead.icon}
                    </Avatar>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={calendar => {
              searchDelayed(calendar.target.value);
            }}
          />
        </Box>

        <Grid container spacing={2}>
          {companies.map(company => (
            <Grid key={company.APS_ID} item xs={12} sm={6} md={4} lg={3}>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100px",
                  alignItems: "center",
                  padding: "15px",
                  cursor: "pointer",
                  gap: "10px",
                }}
                onClick={() =>
                  handleOpenCompany(
                    `${company.APS_COD_EMPRESA}${company.APS_COD_FILIAL}`,
                    company.APS_DESC_FILIAL,
                  )
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {company.APS_LOGO_B64 ? (
                    <img
                      src={`data:image/png;base64,${company.APS_LOGO_B64}`}
                      alt="logo da empresa"
                      style={{
                        maxHeight: "35px",
                      }}
                    />
                  ) : (
                    <ImageNotSupported sx={{ height: "35px", width: "35px" }} />
                  )}
                </Box>
                <Typography variant="subtitle1">
                  {`${company.APS_COD_EMPRESA.substring(0, 2)}${
                    company.APS_COD_FILIAL
                  } - ${company.APS_DESC_FILIAL}`}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {companiesQuery?.data &&
          (companiesQuery?.isLoading || companiesQuery?.hasNextPage) && (
            <Box
              ref={sentryRef}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "25px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
      </Box>
    </>
  );
}
