import React, { createContext, useContext, useState, useEffect } from "react";
import { LayoutSplashScreen } from "../../_metronic/layout";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const TOKEN_NAME = "vm-token";

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [partnersToken, setPartnersToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem(TOKEN_NAME);

    if (storedToken) {
      setToken(storedToken);
    }

    const storedParnersToken = localStorage.getItem(`${TOKEN_NAME}-partners`);

    if (storedParnersToken) {
      setPartnersToken(storedParnersToken);
    }

    const storedUser = localStorage.getItem("vm-user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    setLoading(false);
  }, []);

  const login = (tokenValue, user, partnersTokenValue) => {
    localStorage.setItem(TOKEN_NAME, tokenValue);
    localStorage.setItem(`${TOKEN_NAME}-partners`, partnersTokenValue);
    localStorage.setItem(`vm-user`, JSON.stringify(user));

    setToken(tokenValue);
    setPartnersToken(partnersTokenValue);
    setUser(user);
  };

  const logout = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(`${TOKEN_NAME}-partners`);
    localStorage.removeItem("vm-user");

    setToken(null);
    setPartnersToken(null);
    setUser(null);
  };

  if (loading) return <LayoutSplashScreen />;

  return (
    <AuthContext.Provider value={{ token, partnersToken, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
