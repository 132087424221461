import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  MenuItem,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AddCircle, Delete } from "@mui/icons-material";
import { Tabs, Tab } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import GroupDialog from "./GroupDialog";
import { UserDialog } from "./UserDialog";
import { TOTAL_PERMISSION } from "../documentPermission";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
  },
  permission: {
    width: 150,
  },
}));

export default function FilePropertiesDialog({
  open,
  id,
  permissions,
  name,
  description,
  inheritPermission,
  onClose,
  onConfirm,
  permission,
  safeCode,
}) {
  const classes = useStyles();

  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [currentName, setCurrentName] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentSafeCode, setCurrentSafeCode] = useState("");
  const [currentPermissions, setCurrentPermissions] = useState([]);
  const [currentInheritPermission, setCurrentInheritPermission] = useState(
    false,
  );

  const handleGroupDialogClose = () => {
    setGroupDialogOpen(false);
  };

  const handleUserDialogClose = () => {
    setUserDialogOpen(false);
  };

  const handleDeletePermission = id => {
    setCurrentPermissions(
      currentPermissions.filter(permission => {
        return permission.id !== id;
      }),
    );
  };

  const handleConfirm = () => {
    onConfirm({
      id: id,
      name: currentName,
      description: currentDescription,
      permissions: currentPermissions,
      inheritPermission: currentInheritPermission,
      safeCode: currentSafeCode,
    });
  };

  useEffect(() => {
    setCurrentName(name);
    setCurrentDescription(description);
    setCurrentPermissions(permissions);
    setCurrentInheritPermission(inheritPermission);
    setCurrentSafeCode(safeCode);
  }, [description, inheritPermission, name, permissions, safeCode]);

  useEffect(() => {
    if (currentInheritPermission) {
      setCurrentPermissions([]);
    }
  }, [currentInheritPermission]);

  return (
    <>
      <GroupDialog
        open={groupDialogOpen}
        onClose={handleGroupDialogClose}
        onConfirm={group => {
          setCurrentPermissions([
            ...currentPermissions,
            {
              id: uuidv4(),
              type: 2,
              recipient: {
                id: group.value,
                description: group.label,
              },
              securityLevel: 1,
            },
          ]);
          setGroupDialogOpen(false);
        }}
      />
      <UserDialog
        open={userDialogOpen}
        onClose={handleUserDialogClose}
        onConfirm={user => {
          setCurrentPermissions([
            ...currentPermissions,
            {
              id: uuidv4(),
              type: 1,
              recipient: {
                id: user.value,
                description: user.label,
              },
              securityLevel: 1,
            },
          ]);
          setUserDialogOpen(false);
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={onClose}
        aria-labelledby="Propriedades do arquivo"
      >
        <DialogTitle>Propriedades de {name}</DialogTitle>
        <DialogContent>
          <Tabs defaultActiveKey="basic">
            <Tab eventKey="basic" title="Básico">
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    variant="standard"
                    label="Nome"
                    value={currentName}
                    fullWidth={true}
                    margin="normal"
                    onChange={event => {
                      setCurrentName(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    variant="standard"
                    label="Descriçao"
                    value={currentDescription}
                    fullWidth={true}
                    margin="normal"
                    onChange={event => {
                      setCurrentDescription(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <TextField
                    variant="standard"
                    type="number"
                    label="Código de segurança"
                    value={currentSafeCode}
                    fullWidth={true}
                    margin="normal"
                    onChange={event => {
                      setCurrentSafeCode(event.target.value);
                    }}
                  />
                </div>
              </div>
              {permission >= TOTAL_PERMISSION && (
                <div className="row">
                  <div className="col-lg-12">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={currentInheritPermission}
                          onChange={event => {
                            setCurrentInheritPermission(event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label="Herdar permissões"
                    />
                  </div>
                </div>
              )}
            </Tab>
            {permission >= TOTAL_PERMISSION && (
              <Tab
                eventKey="permissions"
                title="Permissões"
                disabled={currentInheritPermission}
              >
                <div className="row mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddCircle />}
                    onClick={() => {
                      setUserDialogOpen(true);
                    }}
                  >
                    Usuário
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddCircle />}
                    onClick={() => {
                      setGroupDialogOpen(true);
                    }}
                  >
                    Grupo
                  </Button>
                </div>
                <div className="row">
                  <TableContainer>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Usuário ou grupo</TableCell>
                          <TableCell align="right">Tipo</TableCell>
                          <TableCell align="right">Privilégio</TableCell>
                          <TableCell align="right">Excluir</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentPermissions.map((permission, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell component="th" scope="row">
                                {permission.recipient.description}
                              </TableCell>
                              <TableCell align="right">
                                {permission.type}
                              </TableCell>
                              <TableCell align="right">
                                <TextField
                                  select
                                  value={permission.securityLevel}
                                  className={classes.permission}
                                  onChange={event => {
                                    setCurrentPermissions(
                                      currentPermissions.map(p => {
                                        if (p.id === permission.id) {
                                          return {
                                            ...p,
                                            securityLevel: event.target.value,
                                          };
                                        }

                                        return p;
                                      }),
                                    );
                                  }}
                                  variant="outlined"
                                  size="small"
                                  margin="none"
                                >
                                  <MenuItem value={1}>Leitura</MenuItem>
                                  <MenuItem value={2}>Gravação</MenuItem>
                                  <MenuItem value={3}>Edição</MenuItem>
                                  <MenuItem value={4}>Total</MenuItem>
                                </TextField>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => {
                                    handleDeletePermission(permission.id);
                                  }}
                                  size="large"
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Tab>
            )}
          </Tabs>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Fechar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
