import React, { useRef } from "react";
import IframeResizer from "iframe-resizer-react";

export function HomePage() {
  const iframeRef = useRef(null);

  return (
    <IframeResizer
      forwardRef={iframeRef}
      heightCalculationMethod="lowestElement"
      inPageLinks
      src={"https://news.rhdigitalexe.com.br"}
      style={{
        width: "1px",
        minWidth: "100%",
        border: "0px",
        height: "100%",
      }}
    />
  );
}
