import React from "react";
import { adaptV4Theme } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

const theme = createTheme(
  adaptV4Theme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
      // direction: "rtl",
      typography: {
        fontFamily: ["Poppins"].join(","),
      },

      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: "#3C8DBC",
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: will be calculated from palette.primary.main,
          main: "#f69c3b",
          // dark: will be calculated from palette.primary.main,
          contrastText: "#fff", //will be calculated to contrast with palette.primary.main
        },
        error: {
          // light: will be calculated from palette.primary.main,
          main: "#f018a6",
          // dark: will be calculated from palette.primary.main,
          // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
        },
      },
      components: {
        // Name of the component ⚛️
        MuiButtonBase: {
          defaultProps: {
            // The properties to apply
            disableRipple: true, // No more ripple, on the whole application 💣!
          },
        },
        MuiPopover: {
          defaultProps: {
            // Set default elevation to 1 for popovers.
            elevation: 1,
          },
        },
      },
    },
  ),
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
