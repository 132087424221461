import { Chat } from "@mui/icons-material";
import { Backdrop, Box, Paper } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { makeStyles } from "@mui/styles";

import { getAvaiableChat, getHelpdeskUrl } from "../../services/vmApi";
import { LoadingBox } from "../../../components/utils";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

export function Help() {
  const classes = useStyles();

  const helpQuery = useQuery(["help"], () => getHelpdeskUrl(), {
    refetchInterval: 3 * 60 * 1000,
  });
  const helpdeskUrl = helpQuery.data?.data?.url;

  const chatQuery = useQuery(["chat"], () => getAvaiableChat(), {
    refetchInterval: 60000,
  });
  const avaiableChat = chatQuery.data?.data?.avaiable;
  const chatJwt = chatQuery.data?.data?.user_jwt;

  const loading = helpQuery.isLoading || chatQuery.isLoading;

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <LoadingBox />
      </Backdrop>
      <Box
        sx={{
          padding: "25px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <a
          className="menu-link"
          href={helpdeskUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              padding: "15px",
              gap: "15px",
            }}
          >
            <Chat />
            Iniciar solicitação ou reportar algum problema
          </Paper>
        </a>
        {avaiableChat && (
          <a
            className="menu-link"
            href={`https://rhdigitalexe.tomticket.com/chatauth/?id=EP65466&ac=4189824P14042024075046&jwt=${chatJwt}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                padding: "15px",
                gap: "15px",
              }}
            >
              <Chat />
              Chat online
            </Paper>
          </a>
        )}
      </Box>
    </>
  );
}
