const VACATION = 1;
const ABSENCE = 2;

export default function getRequestType(type) {
  switch (type) {
    case VACATION:
      return "🏖️ Férias";
    case ABSENCE:
      return "🤕 Afastamento";
    default:
      return "#Desconhecido";
  }
}
