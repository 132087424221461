/* eslint-disable no-useless-computed-key */
import React from "react";
import { Folder, InsertDriveFileOutlined } from "@mui/icons-material";
import {
  List,
  ListItem,
  Breadcrumbs,
  Pagination,
  PaginationItem,
  Link as MuiLink,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";

import ContextMenu from "./ContextMenu";
import FilePropertiesDialog from "./FilePropertiesDialog";
import DocumentViewerDialog from "./DocumentViewerDialog";
import AddFolderDialog from "./AddFolderDialog";

const useStyles = makeStyles(() => ({
  root: {
    padding: 15,

    "&.overdir": {
      backgroundColor: "#e8f0fe",
      border: "2px solid #3c8dbc",
    },
  },
  header: {
    display: "flex",
    paddingTop: 20,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 8,
    fontWeight: "bold",
  },
  pagination: {
    marginTop: 5,
    float: "right",
  },
  itemId: {
    display: "inline-flex",
    minWidth: 56,

    ["@media screen and (max-width: 768px)"]: {
      display: "none",
    },
  },
  itemType: {
    display: "inline-flex",
    minWidth: 56,
  },
  itemName: {
    display: "inline-flex",
    width: "100%",
    minWidth: 200,

    ["@media screen and (max-width: 768px)"]: {
      "& span": {
        display: "block",
        width: 180,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
  },
  itemSize: {
    display: "inline-flex",
    minWidth: 100,

    ["@media screen and (max-width: 768px)"]: {
      display: "none",
    },
  },
  itemModified: {
    display: "inline-flex",
    minWidth: 100,

    ["@media screen and (max-width: 768px)"]: {
      display: "none",
    },
  },
  list: {
    margin: 0,
    padding: 0,

    "& li": {
      height: "48px",

      display: "flex",
      borderTop: "1px solid #dadce0",
      paddingLeft: "16px",
      paddingRight: "16px",
      alignItems: "center",

      "&.overdir": {
        backgroundColor: "#e8f0fe",
        border: "2px solid #3c8dbc",
      },

      "&.block-hide": {
        opacity: 0.3,
      },

      "&.fileselected": {
        backgroundColor: "#e8f0fe",
      },

      "&.fileclick": {
        backgroundColor: "#e8f0fe",
        border: "1px solid #3c8dbc",
      },

      "&:hover": {
        "&:not(.fileselected)": {
          backgroundColor: "#f5f5f5",

          "&.svg-icon-sort": {
            opacity: 1,
          },
        },
      },
    },
  },
}));

export default function FileManager({
  loading,
  folderId,
  docs,
  breadcrumbs,
  page,
  maxPages,
  handleDragStart,
  handleDragEnd,
  handleCurrentFolderDragOver,
  handleCurrentFolderDragLeave,
  handleCurrentFolderDrop,
  handleDragOver,
  handleDragLeave,
  handleClick,
  handleMouseDown,
  handleDrop,
  handleContextMenu,
  handleDoubleClick,
  contextMenu,
  filePropertiesDialog,
  handleFilePropertiesDialog,
  documentViewerDialog,
  handleDocumentViewerDialog,
  newFolderDialog,
  handleNewFolderDialog,
  onDownload,
  onDelete,
  onAddNewFolder,
  onUpdateFileProperties,
  permission,
}) {
  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      <div id="fm" className={classes.root}>
        <div
          onDragOver={handleCurrentFolderDragOver}
          onDragLeave={handleCurrentFolderDragLeave}
          onDrop={handleCurrentFolderDrop}
        >
          <div className="mb-5">
            <Breadcrumbs aria-label="Breadcrumb">
              {breadcrumbs.map((breadcrumb, i) => {
                return (
                  <Link key={i} color="inherit" to={breadcrumb.pathname}>
                    {breadcrumb.title}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </div>
          <div className={classes.header}>
            <div className={classes.itemId}>
              <span>ID</span>
            </div>
            <div className={classes.itemType}>
              <span>Tipo</span>
            </div>
            <div className={classes.itemName}>
              <span>Nome</span>
            </div>
            <div className={classes.itemSize}>
              <span>Tamanho</span>
            </div>
            <div className={classes.itemModified}>
              <span>Modificado</span>
            </div>
          </div>
        </div>
        {docs.length === 0 && loading ? (
          <div style={{ flex: 1, textAlign: "center", paddingTop: "40px" }}>
            <span>Carregando...</span>
          </div>
        ) : docs.length ? (
          <>
            <List className={classes.list}>
              {docs.map((item, i) => {
                return (
                  <ListItem
                    key={i}
                    file-id={item.id}
                    draggable={true}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onClick={handleClick}
                    onMouseDown={handleMouseDown}
                    onDrop={handleDrop}
                    onContextMenu={handleContextMenu}
                    onDoubleClick={handleDoubleClick}
                  >
                    <div className={classes.itemId}>
                      <span>{item.id}</span>
                    </div>
                    <div className={classes.itemType}>
                      {item.type === "dir" && (
                        <Folder style={{ fontSize: 32 }} />
                      )}
                      {item.type === "file" && (
                        <InsertDriveFileOutlined style={{ fontSize: 32 }} />
                      )}
                    </div>
                    <div className={classes.itemName}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{item.name}</span>
                        {item.relatedTaskId && (
                          <span style={{ fontSize: "11px" }}>
                            Tarefa vinculada:{" "}
                            <MuiLink
                              href={`/bpm/task?id=${item.relatedTaskId}`}
                            >
                              {item.relatedTaskId}
                            </MuiLink>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className={classes.itemSize}>
                      <span>{item.size}</span>
                    </div>
                    <div className={classes.itemModified}>
                      <span>{item.updatedAt}</span>
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <div className={classes.pagination}>
              <Pagination
                count={maxPages}
                page={page}
                renderItem={item => (
                  <PaginationItem
                    component={Link}
                    to={{
                      pathname: location.pathname,
                      search: new URLSearchParams({
                        folder_id: folderId,
                        page: item.page,
                      }).toString(),
                    }}
                    {...item}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <div style={{ flex: 1, textAlign: "center" }}>
            <span>Pasta está vazia</span>
          </div>
        )}
        <ContextMenu
          show={contextMenu.show}
          x={contextMenu.x}
          y={contextMenu.y}
          onDownload={onDownload}
          onDelete={onDelete}
          onProperties={() => handleFilePropertiesDialog({ open: true })}
          permission={permission}
        />
        <FilePropertiesDialog
          id={filePropertiesDialog.id}
          permissions={filePropertiesDialog.permissions}
          name={filePropertiesDialog.name}
          description={filePropertiesDialog.description}
          inheritPermission={filePropertiesDialog.inheritPermission}
          open={filePropertiesDialog.open}
          onClose={() => handleFilePropertiesDialog({ open: false })}
          onConfirm={fileProperties => {
            onUpdateFileProperties(fileProperties);
            handleFilePropertiesDialog({ open: false });
          }}
          permission={permission}
          safeCode={filePropertiesDialog.safeCode}
        />
        <DocumentViewerDialog
          open={documentViewerDialog.open}
          name={documentViewerDialog?.data?.name}
          url={documentViewerDialog?.data?.tmpUrl}
          onClose={() => handleDocumentViewerDialog({ open: false })}
        />
        {newFolderDialog.open && (
          <AddFolderDialog
            onClose={() => handleNewFolderDialog({ open: false })}
            onConfirm={folder => {
              onAddNewFolder(folder);
              handleNewFolderDialog({ open: false });
            }}
          />
        )}
      </div>
    </>
  );
}
