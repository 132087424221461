/* eslint-disable no-sequences */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { object } from "yup";
import { Autocomplete } from "@mui/material";

import { getGroups } from "../../../services/vmApi";

export default function GroupDialog({ open, onClose, onConfirm }) {
  const [errors, setErrors] = useState(null);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [groupOptions, setGroupOptions] = useState([]);
  const [group, setGroup] = useState(null);

  const validateSchema = object({
    group: object()
      .nullable()
      .required(),
  });

  const validateIfHasError = (fieldName, value) => {
    if (errors?.[fieldName]) {
      validateSchema.validateAt(fieldName, { [fieldName]: value }).then(() => {
        const newErrors = errors;
        delete newErrors[fieldName];

        setErrors(newErrors);
      });
    }
  };

  const fetchGroups = useCallback(query => {
    setLoadingGroup(true);
    getGroups({ query })
      .then(({ data }) => {
        setGroupOptions(
          data.data.map(group => ({
            value: group.id,
            label: `${group.id} - ${group.description}`,
          })),
        );
      })
      .catch(() => {
        // TODO: Alerta de erro ao realizar requisição
      })
      .finally(() => {
        setLoadingGroup(false);
      });
  }, []);

  useEffect(() => {
    if (open) {
      fetchGroups();
      setGroup(null);
      setErrors(null);
    }
  }, [fetchGroups, open]);

  const handleConfirm = () => {
    const permission = {
      group: group,
    };

    validateSchema
      .validate(permission, { abortEarly: false })
      .then(() => {
        onConfirm(group);
      })
      .catch(err => {
        setErrors(
          err.inner.reduce(
            (obj, item) => ((obj[item.path] = item.message), obj),
            {},
          ),
        );
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Vincular grupo</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-lg-12">
            <Autocomplete
              options={groupOptions}
              getOptionLabel={option => option.label}
              isOptionEqualToValue={(option, value) => {
                return option.value === value.value;
              }}
              loading={loadingGroup}
              renderInput={params => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Grupo"
                  margin="normal"
                  required
                  fullWidth
                  error={errors?.group ? true : false}
                  helperText={errors?.group} />
              )}
              onInputChange={(_, value) => {
                fetchGroups(value);
              }}
              onChange={(event, element) => {
                validateIfHasError("group", element);
                setGroup(element);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

GroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
