import axios from "axios";
import { TOKEN_NAME } from "../contexts/AuthContext";

const BASE_URL = process.env.REACT_APP_MOBILE_URL;

const mobileInstance = axios.create();

mobileInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(`${TOKEN_NAME}-partners`);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export function getUsers(queryParams = {}) {
  return mobileInstance.get(`${BASE_URL}/employee`, {
    params: queryParams,
  });
}

export function getUser(companyCode, subsidiaryCode, userId) {
  return mobileInstance.get(`${BASE_URL}/employee/${userId}`, {
    params: {
      company_code: companyCode,
      subsidiary_code: subsidiaryCode,
    },
  });
}

export function storeUser({
  companyCode,
  subsidiaryCode,
  enrollNumber,
  cpf,
  email,
  password,
  checked,
}) {
  return mobileInstance.post(`${BASE_URL}/employee`, {
    company_code: companyCode,
    subsidiary_code: subsidiaryCode,
    enroll_number: enrollNumber,
    cpf,
    email,
    password,
    checked,
  });
}

export function updateUser(
  id,
  { companyCode, subsidiaryCode, enrollNumber, cpf, email, password, checked },
) {
  return mobileInstance.put(`${BASE_URL}/employee/${id}`, {
    company_code: companyCode,
    subsidiary_code: subsidiaryCode,
    enroll_number: enrollNumber,
    cpf,
    email,
    password,
    checked,
  });
}

export function getRequests(queryParams = {}) {
  return mobileInstance.get(`${BASE_URL}/admin-request`, {
    params: queryParams,
  });
}

export function getRequest(companyCode, subsidiaryCode, requestId) {
  return mobileInstance.get(`${BASE_URL}/admin-request/${requestId}`, {
    params: {
      company_code: companyCode,
      subsidiary_code: subsidiaryCode,
    },
  });
}

export function approveRequest(requestId, { companyCode, subsidiaryCode }) {
  return mobileInstance.post(`${BASE_URL}/admin-request/${requestId}/approve`, {
    company_code: companyCode,
    subsidiary_code: subsidiaryCode,
    from: "external",
  });
}

export function rejectRequest(requestId, { companyCode, subsidiaryCode }) {
  return mobileInstance.post(`${BASE_URL}/admin-request/${requestId}/reject`, {
    company_code: companyCode,
    subsidiary_code: subsidiaryCode,
    from: "external",
  });
}

export function integrateRequest(requestId, { companyCode, subsidiaryCode }) {
  const vmToken = localStorage.getItem(TOKEN_NAME);

  return mobileInstance.post(
    `${BASE_URL}/admin-request/${requestId}/integrate`,
    {
      company_code: companyCode,
      subsidiary_code: subsidiaryCode,
      from: "external",
      integration_token: `Bearer ${vmToken}`,
    },
  );
}

export function getRequestStatistics() {
  return mobileInstance.get(`${BASE_URL}/statistic/admin-request`);
}

export function getEmployeeStatistics() {
  return mobileInstance.get(`${BASE_URL}/statistic/employee`);
}
