import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function HealthTable({ data }) {
  const columns = useMemo(
    () => [
      {
        headerName: "Nome",
        field: "exists",
        flex: 1,
        renderCell: params => {
          const exists = params.row.exists;

          return `${exists ? "🟢" : "🔴"} ${params.row.name}`;
        },
      },
      {
        headerName: "Descrição",
        field: "description",
        flex: 3,
      },
    ],
    [],
  );

  return (
    <DataGrid
      rows={data}
      columns={columns}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
    />
  );
}
