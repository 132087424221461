import React, { Suspense, lazy } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { LayoutSplashScreen } from "../_metronic/layout";
import { HomePage } from "./pages/HomePage";
import ECM from "./modules/ECM";
import MyCompany from "./modules/Business/pages/MyCompany";
import { Help } from "./modules/Help";

const AdminRoutes = lazy(() => import("./modules/Admin"));
const AnalyticsRoutes = lazy(() => import("./modules/Analytics"));
const BPMRoutes = lazy(() => import("./modules/BPM"));
const OperationalRoutes = lazy(() => import("./modules/Operational"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Routes>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="home" element={<HomePage />} can="home-page" />
        <Route path="ecm" element={<ECM />} can="documents-page" />
        <Route path="bpm/*" element={<BPMRoutes />} can="task-center-page" />
        <Route path="business/my-company" element={<MyCompany />} />
        <Route path="operational/*" element={<OperationalRoutes />} can="task-center-page" />
        <Route
          path="/analytics/*"
          element={<AnalyticsRoutes />}
          can="analytics-page"
        />
        <Route path="admin/*" element={<AdminRoutes />} can="settings-page" />
        <Route path="help" element={<Help />} />

        {/*<Redirect to="error/error-v1" />*/}
      </Routes>
    </Suspense>
  );
}
