import React, { useMemo } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

export default function EmployeeTable({ data, rows }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = React.useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = paginationModel => {
    searchParams.set("page", paginationModel.page + 1);
    searchParams.set("page_size", paginationModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(paginationModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Matricula",
        field: "ZRA_MAT",
        flex: 1,
      },
      {
        headerName: "CPF",
        field: "ZRA_CPF",
        flex: 1,
      },
      {
        headerName: "Nome",
        field: "ZRA_NOME",
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        width: 70,
        getActions: params => [
          <GridActionsCellItem
            onClick={() => {
              console.log(params.row.ZRA_MAT);
            }}
            label="Exibir subordinados"
            showInMenu
          />,
        ],
      },
    ],
    [],
  );

  return (
    <DataGrid
      getRowId={row => row.ZRA_CPF}
      rows={data}
      columns={columns}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}
