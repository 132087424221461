import React from "react";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  loadingBox: {
    display: "flex",
    width: "100px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.loadingBox}>
        <div className="mr-2">
          <Spinner animation="border" />
        </div>
        <span>Carregando...</span>
      </div>
    </>
  );
};
