import React from "react";
import { Navigate, Routes as RRDRoutes, Route } from "react-router-dom";

import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useAuth } from "./contexts/AuthContext";

export function Routes() {
  const { token } = useAuth();
  const isAuthorized = token != null;

  return (
    <RRDRoutes>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="auth/login" element={<AuthPage />} />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Route path="auth/*" element={<Navigate to="/" />} />
      )}

      <Route path="error" element={<ErrorsPage />} />
      <Route path="logout" element={<Logout />} />

      {!isAuthorized ? (
        <Route path="/" element={<Navigate to="auth/login" />} />
      ) : (
        <Route path="*" element={<Layout />}>
          <Route path="*" element={<BasePage />} />
        </Route>
      )}
    </RRDRoutes>
  );
}
