export default function randPassword(letters, numbers, either, special = 1) {
  var chars = [
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    "0123456789",
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    "*!@#$"
  ];

  return [letters, numbers, either, special]
    .map(function(len, i) {
      return Array(len)
        .fill(chars[i])
        .map(function(x) {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join("");
    })
    .concat()
    .join("")
    .split("")
    .sort(function() {
      return 0.5 - Math.random();
    })
    .join("");
}
